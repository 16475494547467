import React from 'react'

const NextArrowPrev = () => {
    return (
        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.18631 3.01824C6.18443 2.81424 6.10223 2.61921 5.95754 2.47539L4.29409 0.808067C4.22144 0.735849 4.12317 0.695313 4.02073 0.695312C3.91829 0.695313 3.82001 0.735849 3.74736 0.808067C3.71102 0.844114 3.68218 0.886999 3.66249 0.93425C3.6428 0.981501 3.63267 1.03218 3.63267 1.08337C3.63267 1.13456 3.6428 1.18524 3.66249 1.23249C3.68218 1.27974 3.71102 1.32263 3.74736 1.35867L5.02306 2.63049H1.14556C1.04272 2.63049 0.944099 2.67134 0.871382 2.74406C0.798665 2.81678 0.757813 2.9154 0.757812 3.01824C0.757813 3.12108 0.798665 3.2197 0.871382 3.29242C0.944099 3.36514 1.04272 3.40599 1.14556 3.40599H5.02306L3.74736 4.68169C3.67435 4.75419 3.63313 4.85272 3.63276 4.95562C3.6324 5.05851 3.67293 5.15734 3.74543 5.23035C3.81793 5.30337 3.91646 5.34459 4.01936 5.34495C4.12225 5.34532 4.22108 5.30479 4.29409 5.23229L5.95754 3.56497C6.10317 3.4202 6.18545 3.22358 6.18631 3.01824V3.01824Z" fill="white" />
        </svg>

    )
}

export default NextArrowPrev

import React from "react";
import { LibraryHead } from "../../libraryPage.styled";


const LibraryHeadPart = (props) => {
const {text}=props;

  return (
    <LibraryHead>
      {text}
    </LibraryHead>
  )
}
export default LibraryHeadPart;
import React from "react";
import {
  CalloutWrap,
  ColorSec,
  ColorWrap,
  ItemRHS,
  ItemWrap,
} from "../../brandkit-styled";
import KitItemHead from "../kitItemHead";
import ColorPicker from "../ColorPicker/colorPicker";
import { useBrandKit } from "../../context/brandContext";
import { toast } from "react-hot-toast";
import BrandkitPreview from "../BrandKitPreview/brandkitPreview";

const CalloutKit = () => {
  const { brandkitState, brandkitDispatch,updateBrandKit} = useBrandKit();
  const { brandKit,callout } = brandkitState;
  const handleCancel = () => {
    if (callout?.open) {
      brandkitDispatch({
        type: "HANDLE_CALLOUT",
        data: false,
      });
    }
  };
  const handleColorChange = (newColor, design) => {
    brandkitDispatch({
      type: "HANDLE_CALLOUT_COLOR",
      data: {
        bgColor: design === "bg" ? newColor?.hex : brandKit?.callout?.backgroundColor,
        textColor: design === "text" ? newColor?.hex : brandKit?.callout?.textColor,
        btnColor: design === "btn" ? newColor?.hex : brandKit?.callout?.buttonColor,
      },
    });
  };
  const saveFn =(type)=>{
    brandkitDispatch({
      type: "HANDLE_CALLOUT",
      data: false,
    });
    let apiBody = {

      ...brandKit,
     callout:brandKit?.callout
      
    };
    const apiObject = {
      method: "post",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(apiBody),
    };
    updateBrandKit(apiObject)
      .then((data) => {
        let userBrandKitDetails =
          data?.userBrandKitDetails;
        brandkitDispatch({
          type: "FETCH_BRAND_KIT",
          data: userBrandKitDetails,
        });
        toast?.success('Callout updated...')
      })
      .catch((error) => {
        console.error("Error:", error);
        toast?.error('Something went wrong...')
      });
  }

  return (
    <ItemWrap mt={true} callout={true}>
      <ItemRHS>
        <KitItemHead
          head={"Callout"}
          subHead={
            "Make your callouts stand out—adjust colors, fonts, and more"
          }
        />
        <CalloutWrap>
          <ColorWrap
            onClick={() => {
              brandkitDispatch({ type: "HANDLE_CALLOUT", data: "bg" });
            }}
          >
            <div>Bg Color</div>
            <ColorSec bg={brandKit?.callout?.backgroundColor} />
            {callout?.open === "bg" && (
              <ColorPicker
                cancelFn={handleCancel}
                handleColorChange={handleColorChange}
                design={"bg"}
                color={brandKit?.callout?.backgroundColor}
                saveFn={saveFn}
              />
            )}
          </ColorWrap>
          <ColorWrap
            onClick={() => {
              brandkitDispatch({ type: "HANDLE_CALLOUT", data: "text" });
            }}
          >
            <div>Text Color</div>
            <ColorSec bg={brandKit?.callout?.textColor} />
            {callout?.open === "text" && (
              <ColorPicker
                cancelFn={handleCancel}
                handleColorChange={handleColorChange}
                design={"text"}
                color={brandKit?.callout?.textColor}
                saveFn={saveFn}
              />
            )}
          </ColorWrap>
          <ColorWrap
            onClick={() => {
              brandkitDispatch({ type: "HANDLE_CALLOUT", data: "btn" });
            }}
          >
            <div>Button Color</div>
            <ColorSec bg={brandKit?.callout?.buttonColor} />
            {callout?.open === "btn" && (
              <ColorPicker
                cancelFn={handleCancel}
                handleColorChange={handleColorChange}
                design={"btn"}
                color={brandKit?.callout?.buttonColor}
                saveFn={saveFn}
              />
            )}
          </ColorWrap>
        </CalloutWrap>
      </ItemRHS>
      <BrandkitPreview type="callout" />
    </ItemWrap>
  );
};

export default CalloutKit;

import styled from "styled-components"

export const DownloadNote = styled.div`
position:fixed;
z-index:999;
display:flex;
align-items: center;
top:${props=>(props.upl==="Upload"?"21%":"15%")};;
right:2%;
width:14%;
height:50px;
background-color:white;
color: #3C453C;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
z-index:99999;
box-shadow: 0px 4px 4px #DCE6FF;
animation:SlideIn .3s ease-in-out ;
.in-progress{
margin-left:10px;
}
.container{
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;

    .outer{
    background-color: #d9d9d9;
    position:relative;
    width:30px;
    height:30px;
    border-radius:50%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.inner{
    width:22.5px;
    height:22.5px;
    background-color: white;
    border-radius:50%;
    circle{
    fill:none;
    stroke:#6A36F0;
    stroke-width:10px;
}

}

}



@keyframes SlideIn{
    0%{
      transform:translateX(100%)
    }
    100%{
      transform:translateX(0)
    }
} 


` 
export const RightDiv = styled.div`
background: #743AEA;
height: 100%;
width:5%;
right:0;
top:0;
position:absolute;

`


import React from 'react'

const StepArrowPrev = (props) => {
    return (
        <svg width="9" height="9" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6L10.1894 0.803848L10.1894 11.1962L0 6Z" fill={props?.bg} />
        </svg>
    )
}

export default StepArrowPrev

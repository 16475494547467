import styled from "styled-components";
import theme from "../../../theme";
import brandPreviewImglogo from "./Images/brandPreviewImg.svg"
import brandPrevBg from "./Images/brandPrevBg.svg"
import introBgImg from "./Images/introBgImg.svg"
import outroBgImg from "./Images/outroBgImg.svg"
import noIntro from "./Images/noIntro.svg"
import noOutro from "./Images/noOutro.svg"
import callOutBg from "./Images/callOutBg.svg"
export const Indiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.colors.background};
  overflow-y: auto;
`;
export const BrandContWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0px 50px 24px 50px;

  &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #D9D9D9; 
            border-radius: 6px;
        }

        /* &::-webkit-scrollbar-track:hover {
            background-color: #555; 
        } */

        /* &::-webkit-scrollbar-thumb:hover {
            background-color: #D9D9D9; 
        } */
`;
export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  &.emptycase {
    /* justify-content: center; */
    margin-top: 5.01%;
    height: 90%;
  }
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const BrandHeadWrap = styled.div`
width: 100%;
height: 157px;
border-radius: 20px;
background: #E0D4FD;
display: flex;
justify-content: space-between;
align-items: center;
`
export const BrandHeadTextWrap = styled.div`
display: flex;
flex-direction: column;
padding: 30px;
${theme.breakpoints.tabViewCapt} {
    padding: 25px;
  }
  ${theme.breakpoints.tabViewCapt} {
    padding: 20px;
  }
justify-content: center;
align-items: flex-start;
`

export const BrandHeadMainText = styled.div`
color: #1E2126;
font-family: 'Inter';
font-size: 18px;
${theme.breakpoints.tabViewCapt} {
    font-size: 16px;
  }
  ${theme.breakpoints.tabViewCapt} {
    font-size: 14px;
  }
font-style: normal;
font-weight: 500;
line-height: 150%; /* 27px */
`
export const BrandSubTextWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
margin-top: 9px;
`
export const BrandSubTextCont = styled.div`
display: flex;
gap: 8px;
justify-content: flex-start;
align-items: center;
margin-top: 10px;
${theme.breakpoints.tabViewCapt} {
    gap: 4px;
  }
  ${theme.breakpoints.tabViewCapt} {
    gap: 2px;
  }
`
export const ClickIcon = styled.img`
width: 20px;
height: 20px;
`
export const BrandSubText = styled.div`
color: #667085;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: 0.5px;
text-align: left;
${theme.breakpoints.tabViewCapt} {
    font-size: 12px;
}
${theme.breakpoints.tab} {
    font-size: 10px;
}
`
export const HeadImg = styled.img`

`

export const BrandItemsWrap = styled.div`
width: 100%;
height: 670px;
border-radius: 20px;
background: #FFF;
overflow-y: auto;
margin-top: 24px;
padding: 24px 22px;
&::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #D9D9D9; 
            border-radius: 6px;
        }
`

export const ItemWrap = styled.div`
padding: 21px 15px 27px 24px;
height:${(props) => props?.font ? '246px' : props?.voice ? '186px' : props?.callout ? '246px' : '291px'};
width: 100%;
border-radius: 8px;
background: #F7F8FA;
display: flex;
justify-content: space-between;
margin-top: ${(props) => props?.mt && '32px'};
`

export const ItemRHS = styled.div`
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: flex-start;
input[type="file"] {
    display: none;
    pointer-events: none;
  }
`
export const ItemHeadWrap = styled.div`
display: flex;
flex-direction: column;
gap: 4px;
justify-content: flex-start;
align-items: flex-start;
`
export const ItemMainHead = styled.div`
color: #1E2126;
font-family: 'Inter';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 27px */
`
export const ItemSubHead = styled.div`
color: #667085;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 13px; /* 92.857% */
letter-spacing: 0.5px;
text-align: left;
`

export const LogoUploadWrap = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
margin-top: 24px;
gap:20px;
`
export const IntroOutroKebab = styled.div`
border: 0.5px solid #0000000F;
box-shadow: 0px 1.43px 3.57px 0px #26334D08;
background: #FFFFFF;
width: 20px;
height: 20px;
border-radius: 71.43px;
display: none;
justify-content: center;
align-items: center;
position: absolute;
top:10px;
right:10px;
`
export const UploadLogoWrap = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
gap: 8px;
cursor: pointer;
.file {
    border: 2px solid black;
    width: 48%;
    height: 67%;
    border-radius: 10px;
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: none;
  }
 &:hover{
  ${IntroOutroKebab}{
    display:flex;
  }
 }
  
`

export const UploadLogoSec = styled.div`
width: 160px;
height: 130px;
border: 1px dashed rgba(0, 0, 0, 0.2);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 5px;
border-radius: 4px;

`
export const SizeWrap = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 4px;
`
export const RadioBtn = styled.div`
width: 12px;
height: 12px;
flex-shrink: 0;
outline: 1px solid #667085;
border-radius: 50%;
`
export const RadioChecked = styled.div`
width: 12px;
height: 12px;
flex-shrink: 0;
outline: 1px solid rgba(101, 40, 247, 1);
border-radius: 50%;
padding: 2px;
div{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(101, 40, 247, 1);
}
`
export const SizeText = styled.div`
color: #667085;
font-family: 'Inter';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 13px; /* 108.333% */
letter-spacing: 0.5px;
`
export const DefaultWrap = styled(UploadLogoWrap)`

`
export const DefaultLogo = styled(UploadLogoSec)`
border-radius: 4px;
border:${(props) => props?.bord ? '1px solid #B293FB' : 'none'} ;
background: #FFF;
.log-kit{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
`
export const BackGroundWrap = styled(LogoUploadWrap)`

`
export const ChangeBgWrap = styled(UploadLogoWrap)`

`
export const ChangeBgSec = styled(UploadLogoSec)`
position: relative;
.sketch-picker {
    position: absolute;
    left: 20px;
    top: 30px;
    z-index: 99999999;
    width: 240px!important;

  height: 360px !important;
  .flexbox-fix > *:nth-child(n+10) {
  display: none; /* Hide the 10th and subsequent children */
}
  }

`
export const DefaultBg = styled(DefaultLogo)`
background-color: ${(props) => props?.bg ? props?.bg : ''};
outline:${(props) => props?.bord ? '1px solid #B293FB' : 'none'}!important ;
`
export const VideoUploadWrap = styled(LogoUploadWrap)`
`

export const UploadVideoWrap = styled(UploadLogoWrap)`
position: relative;
`
export const UploadVideoSec = styled(UploadLogoSec)`

`
export const FontStyleWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
width: 100%;
margin-top: 24px;
`
export const HeadingDescWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 20px;
height: 70px;
background-color:#FFF;
cursor: pointer;
&.desc{
  margin-top: 18px;
}

`
export const TextWrap = styled.div`
color: #1E2126;
font-family: 'Inter';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
&.desc{
font-size: 16px;
font-weight: 400;
}
`
export const HeadingDescOpenedWrap = styled.div`
width: 100%;
height: 130px;
display: flex;
flex-direction: column;
padding: 18px 18px 20px 20px;
border-radius: 4px;
border: 1px solid #B293FB;
background: #F7F8FA;
`

export const FontSecWrap = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
gap: 20px;
width: 100%;
`

export const FontFamily = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
background-color: #fff;
width: 42.3%;
height: 45px;
padding: 12px;
color: #667085;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: 0.5px;
position: relative;
cursor: pointer;
img{
  transform: ${(props) => props?.arr && 'rotate(180deg)'};
}
`
export const FontSize = styled(FontFamily)`
width: 17.3%;
`

export const HeadingSaveWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 18px;
`

export const SaveCancelWrap = styled.div`
display: flex;
justify-content: flex-start;
gap: 10px;
align-items: center;
div{
  width: 0.5px;
height: 13px;
background-color: #848485;
}
img{
  cursor: pointer;
}
`
export const CalloutWrap = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
height: 86px;
border-radius: 4px;
background: #FFF;
padding:20px 0px 20px 20px;
width: 100%;
margin-top: 24px;
gap: 20px;
`
export const ColorWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 5px;
color: #667085;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: 0.5px;
background-color:rgba(247, 248, 250, 1) ;
padding: 12px;
position: relative;
.sketch-picker {
    position: absolute;
    left: 0px;
    bottom: 50px;
    z-index: 99999999;
    width: 240px!important;

  height: 360px !important;
  .flexbox-fix > *:nth-child(n+10) {
  display: none; /* Hide the 10th and subsequent children */
}
  }

`
export const ColorSec = styled.div`
border-radius: 2px;
background: ${(props) => props?.bg && props?.bg};
width: 18px;
height: 18px;
cursor: pointer;
`
export const SaveCancelCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap:2px;
  position: absolute;
  bottom: 15px;
    position: absolute;
          top: ${(props) => props?.design ? '-85px' : '370px'};
          left: ${(props) => props?.design === 'bg' ? '85%' : props?.design === 'text' ? '70%' : props?.design === 'btn' ? '53%' : '79%'};
          transform:${(props) => props?.design ? 'translateX(20%)' : 'translateX(-10%)'};
          z-index: 99999999;
`;
export const CancelBtn = styled.div`
  display: flex;
  height:33px;
  padding: 0px 20px;

  justify-content: center;
  align-items: center;
  gap: 94px;
  border-radius: 5px;
  border: none;
  color: #5429ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  background-color: none;
  cursor: pointer;

`;
export const ColorSaveBtn = styled.button`
      
          border: 'none';
          cursor: pointer;
          border-radius: 4px;
          background-color: #6528F7;
          border: 1px solid rgba(0, 0, 0, 0);
          color: #FFF;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.07px;
          z-index:99999999;
          height: 33px;
      padding: 0px 20px;



`

export const FontFamilyMenuWrap = styled.div`
width:100%;
height:218px;
border-radius: 5px;
background: #FFF;
box-shadow: 0px 5px 10px 0px rgba(64, 75, 89, 0.20);
padding: 10px 12px;
text-align:left ;
position: absolute;
left: 0;
top:45px;
z-index: 999999;
`
export const FontFamilyMenuItem = styled.div`
color: #404B59;
font-feature-settings: 'liga' off, 'clig' off;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17.194px; /* 122.814% */
letter-spacing: 0.3px;
height: 40px;
width: 100%;
justify-content: flex-start;
gap:4px;
display: flex;
align-items: center;
padding: 0px 12px;
cursor: pointer;
&:hover{
  border-radius: 4px;
background: #F4F6F8;
}
`
export const FontSizeMenuWrap = styled(FontFamilyMenuWrap)`
height:258px;
`
export const FontSizeMenuItem = styled(FontFamilyMenuItem)`
`
export const TotalPreviewContBrand = styled.div`
/* width:35.950%;
height:100%; */
width:380px;
height:100%;
background: #E0D4FD;
border-radius: 8px;
display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`

export const PreviewImageDiv = styled.div`
width: 90.35%;
height:88.827%;
background: ${(props) => props.type === "logo" ? `url(${brandPreviewImglogo})` : props.type === "background" ? `url(${brandPrevBg})` : props.type === "intro" ? props?.introVideo ? `url(${introBgImg})` : `url(${noIntro})` : props.type === "outro" ? props?.OutroVideo ? `url(${outroBgImg})` : `url(${noOutro})` : (props.type === "callout" || props?.type === "font") && `url(${callOutBg})`};
background-repeat:no-repeat;
background-size:cover;
position:relative;
`

export const BrandLogoDiv = styled.div`
height:24px;
width:100px;
position: absolute;
    
img{
  width:50%;
  height:50%;
}
`

export const PreviewBgCont = styled.div`
width:73%;
height:79%;
background: ${(props) => `${props?.bg}`};
position:absolute;
border-radius:4px;
`

export const IntroVideo = styled.video`
width:19%;
height:30%;
position: absolute;
left: 4%;
    top: 11%;
`

export const OutroVideo = styled.video`
width:18%;
height:30%;
position: absolute;
bottom: 7%;
left: 6%;
`

export const TotalDescCont = styled.div`
width: 146.57px;
height: 71.19px;
display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 21%;
    top: 25%;

`

export const DescContPrev = styled.div`
width: 92.841%;
height:100%;
background: ${(props) => props?.bg};
border-radius: 3.72px;
padding: 8px;
display: flex;
flex-direction: column;
justify-content: space-between;
`

export const DescHeadingPrev = styled.div`
font-family: ${(props) => props?.font};
font-size: 7.44px;
font-weight: 600;
line-height: 7.44px;
letter-spacing: -0.02em;
text-align: left;
color: ${(props) => props?.bg};
`

export const DescContParaDiv = styled.div`
font-family: ${(props) => props?.font};
font-size: 6.51px;
font-weight: 500;
line-height: 9.77px;
letter-spacing: -0.02em;
text-align: left;
color: ${(props) => `${props?.bg}E5`};
`

export const ButtonDiv = styled.div`
width: 100%;
height:9.31px;
display: flex;
align-items: center;
justify-content: space-between;
`

export const StepNumberText = styled.div`
font-family: ${(props) => props?.font};
font-size: 5.58px;
font-weight: 400;
line-height: 8.38px;
letter-spacing: -0.02em;
text-align: left;
color: ${(props) => `${props?.bg}B2`};
`

export const ArrowButtonDiv = styled.div`
width: 9.31px;
height: 100%;
border-radius: 1.86px;
display: flex;
justify-content: center;
align-items: center;
background: ${(props) => `${props?.bg}`};

`

import React from "react";
import {
  LeftSec,
  LoginCnt,
  RightSec,
  LoginPgQuote,
  LoginPgDesc,
  LoginPgImg,
  
  WrapperRightSide,
} from "./Login.styled";
import loginImg from "./images/loginPgBanner.png";
import flonnect from "../../assets/img/flonnect.svg";
import theme from "../../theme";

const LoginLayout = ({children}) => {
  return (
    <LoginCnt>
      <LeftSec>
        <img src={flonnect} className="logo-img" alt="" draggable="false" loading="lazy" />
        {children}
      </LeftSec>
      <RightSec>

        <WrapperRightSide>
          <div>
            <LoginPgQuote>Streamline your workflow in ease</LoginPgQuote>
            <LoginPgDesc clr={theme.colors.lightText1}>
              Join over 2lakh+ user trusted screen recording and Workspace tool
              where you can streamlines your workflow, saving you valuable time.
              Capture, collaborate, and conquer tasks with ease
            </LoginPgDesc>
          </div>

        <div className="loginPgImgWrapper">

          <LoginPgImg src={loginImg} />
        </div>

        </WrapperRightSide>


      </RightSec>
    </LoginCnt>
  );
};
export default LoginLayout;

import React from "react";
import { Navigate,Outlet,useLocation } from "react-router-dom";
import { useAuth } from "../context/auth";

const PrivateLayout = () =>{
    const auth = useAuth();
    const location = useLocation();
    if(location?.pathname?.includes('workspace/invite')&&!auth?.authState?.user){
        if(location?.pathname?.split('/')[3]){
            localStorage.setItem('inviteCode',location?.pathname?.split('/')[3])
        }
    }

    return auth?.authState?.isAuthenticated? <Outlet/>:<Navigate to='/' state={{from : location}} />
}
export default PrivateLayout;
import styled from 'styled-components'

export const ReLoad=styled.div`
position:fixed;
top:0;
bottom:0;
left:0;
right:0;
display:flex;
align-items: center;
justify-content:center;
z-index: 9999999;


`
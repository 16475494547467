import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { dashClient } from '../../../utils/axios-utils';
import { useState } from 'react';
import PlayerContainer from '../../Dashboard/VideoPlayer/components/Player/Player';
import screenfull from "screenfull";
import play from "../../Dashboard/VideoPlayer/images/play.svg";
const EmbedVideo = () => {
    const params = useParams();
  const [control, setControl] = useState(true);
  const navigate = useNavigate();
  const [thumb, setThumbnail] = useState(true);

  const [isHovering, setIsHovering] = useState(false);
  const [tip, setTip] = useState("");
  const [name, setName] = useState("");
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }
  const PlayerContainerRef = useRef(null);
    const [vdata,setvData] = useState([]);
    const [state, setState] = useState({
      playing: false,
      muted: false,
      volume: 1,
      playbackRate: 1.0,
      played: 0,
      seeking: false,
    });
  const { playing, muted, volume, playbackRate, played, seeking } = state;
  const format = (seconds) => {
    if (isNaN(seconds)) {
      return "00:00";
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };
    const getVideoById = () => {
        const config = {
          url: `/flonnect/api/video/getvideobyid/${params.id}`,
          method: "GET",
        };
    
        return dashClient(config);
      };
      const handleSuccess = (data) => {
        setvData(data?.video)
      };
      const handleAcquireError = (err) => {
        navigate("/access-denied");
      };
      const { isLoading } = useQuery("video-by-id", getVideoById, {
        onSuccess: handleSuccess,
        onError: handleAcquireError,
        refetchOnWindowFocus: false,
        retry: false,
      });
      const mouseLeave = () => {
        if (playing === true) {
          setControl(false);
        } else {
          setControl(true);
        }
      };
      const mouseHover = () => {
        setControl(true);
      };
     
      const PlayRef = useRef(null);
      
      const onPlayPause = () => {
        setThumbnail(false);
        setState({ ...state, playing: !playing });
      };
    
      const handleMuted = () => {
        setState({ ...state, muted: !muted });
      };
    
      
    
     
    
      const handleVolumeChange = (e, newValue) => {
        setState({
          ...state,
          volume: parseFloat(newValue / 100),
          muted: newValue === 0 ? true : false,
        });
      };
    
      const handleVolumeSeekUp = (e, newValue) => {
        setState({
          ...state,
          volume: parseFloat(newValue / 100),
          muted: newValue === 0 ? true : false,
        });
      };
    
      const toggleFullscreen = () => {
        screenfull.toggle(PlayerContainerRef.current);
      };
    
      const handleProgress = (change) => {
        if (!seeking) {
          setState({ ...state, ...change });
        }
      };
      const handleSeekChange = (e, newValue) => {
        PlayRef.current.seekTo(newValue / 100);
        setState({ ...state, played: parseFloat(newValue / 100) });
        setThumbnail(false);
      };
    
      const handelSeekMouseDown = (e, newValue) => {
        setState({ ...state, seeking: true });
      };
    
      const handleSeekMouseUp = (e, newValue) => {
        setState({ ...state, played: parseFloat(newValue / 100) });
        PlayRef.current.seekTo(newValue / 100);
      };
    
      const Reset = () => {
        PlayRef.current.seekTo(
          PlayRef.current.getCurrentTime() - PlayRef.current.getDuration()
        );
        handleProgress();
      };
      const currentTime = PlayRef.current
    ? PlayRef.current.getCurrentTime()
    : "00:00";
  const duration = PlayRef.current ? PlayRef.current.getDuration() : "00:00";
  const elapsedTime = format(currentTime);
  const totalDuration = format(duration);
    return (
        <div>
           
           <PlayerContainer
                mouseLeave={mouseLeave}
                mouseHover={mouseHover}
                control={control}
                PlayerContainerRef={PlayerContainerRef}
                onPlayPause={onPlayPause}
                PlayRef={PlayRef}
                play={play}
                videoSuccess={true}
                videoData={vdata}
                userName={vdata?.email?.split("@")[0]}
                playing={playing}
                muted={muted}
                volume={volume}
                playbackRate={playbackRate}
                handleProgress={handleProgress}
                thumb={thumb}
                setThumbnail={setThumbnail}
                played={played}
                setControl={setControl}
                setTip={setTip}
                tip={tip}
                handleSeekChange={handleSeekChange}
                handleSeekMouseUp={handleSeekMouseUp}
                handelSeekMouseDown={handelSeekMouseDown}
                elapsedTime={elapsedTime}
                totalDuration={totalDuration}
                handleMuted={handleMuted}
                setIsHovering={setIsHovering}
                setName={setName}
                handleVolumeChange={handleVolumeChange}
                handleVolumeSeekUp={handleVolumeSeekUp}
                toggleFullscreen={toggleFullscreen}
                Reset={Reset}
                setstatePlay={setState}
                preventHorizontalKeyboardNavigation={
                  preventHorizontalKeyboardNavigation
                }
                params={params}
                embed = {true}
                statePlay={state}

              />
           
           </div>
    );
};

export default EmbedVideo;
import React from "react";
import { ReLoad } from "./reload-styled";
import webLogo from "./images/WebLogo.svg";

const Reloader = () => {
  return (
    <ReLoad>
      <img src={webLogo} alt="" draggable="false"/>
    </ReLoad>
  );
};
export default Reloader;

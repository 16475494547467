import React from "react";
import { Navigate,Outlet,useLocation} from "react-router-dom";
import { useAuth } from "../context/auth";
import LoginLayout from "../pages/LoginPage/loginLayout";

const PublicLayout = () =>{
    const auth = useAuth();
    const location = useLocation();

    return auth?.authState?.isAuthenticated?<Navigate to = {location?.state?.from?.pathname?location?.state?.from?.pathname:"dashboard/library"} state={{from:location}} />:
    <LoginLayout><Outlet/></LoginLayout>

}

export default PublicLayout;
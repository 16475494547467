import React from 'react'

const DotImage = (props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5.5836" cy="5.94298" r="5.5836" fill={props?.bg} fill-opacity="0.3" />
            <circle cx="5.58376" cy="5.9446" r="3.2571" fill={props?.bg} />
        </svg>
    )
}

export default DotImage

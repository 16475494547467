import React, { useRef } from "react";
import {
  DefaultLogo,
  DefaultWrap,
  IntroOutroKebab,
  ItemRHS,
  ItemWrap,
  LogoUploadWrap,
  RadioBtn,
  RadioChecked,
  SizeText,
  SizeWrap,
  UploadLogoSec,
  UploadLogoWrap,
} from "../../brandkit-styled";
import KitItemHead from "../kitItemHead";
import Info from "../../Images/info.svg";
import AddLogo from "../../Images/addLogo.svg";
import Logo from "../../Images/logo.svg";
import { toast } from "react-hot-toast";
import { useBrandKit } from "../../context/brandContext";
import { useAuth } from "../../../../../context/auth";
import _static from "../../../../../config/static";
import BrandKitKebab from "../brandKitKebab";
import brandKitOptions from "../../Images/brandKitMoreOptions.svg"
import BrandkitPreview from "../BrandKitPreview/brandkitPreview";
let toastId;
const LogoKit = () => {
  const inputFile = useRef(null);
  const { updateBrandKit, brandkitState, brandkitDispatch, } = useBrandKit();
  const { logo, brandKit, introOutroMenu } = brandkitState;
  const auth = useAuth();
  const userName = auth?.authState?.user?.email?.split("@")[0];
  const { api_Url } = _static;
  return (
    <ItemWrap>
      <ItemRHS>
        <KitItemHead
          head={"Logo"}
          subHead={
            "Utilize multiple logo variations to ensure your brand stands out in any context."
          }
        />
        <LogoUploadWrap>
          <UploadLogoWrap
            onClick={() => {
              inputFile.current.click();
            }}
          >
            <UploadLogoSec>
              <img src={AddLogo} />
              <SizeText>Add Logo</SizeText>
            </UploadLogoSec>
            <SizeWrap>
              <img src={Info} />
              <SizeText>Max Size: 100 X 24px</SizeText>
            </SizeWrap>
            <input
              type="file"
              className="file"
              accept="image/*"
              onChange={async (e) => {
                if (e.target.files[0]) {
                  const maxSizeMB = 20 * 1024 * 1024; // 20 MB in bytes
                  if (e.target.files[0].size > maxSizeMB) {
                    toast.error("File size should be less than 20MB.");
                    return;
                  }
                  toastId = toast.loading("Adding Logo...")
                  const reader = new FileReader();
                  reader.onload = function (event) {
                    const img = new Image();

                    img.onload = async function () {
                      // Check if the image dimensions are 100x24
                      if (img.width !== 100 || img.height !== 24) {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = 100;
                        canvas.height = 24;

                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                        let resizedImage = canvas.toDataURL("image/png");
                        resizedImage = resizedImage.split(",")[1]
                        const base64 = resizedImage.replace(/-/g, '+').replace(/_/g, '/');

                        const binaryString = atob(base64);
                        const uint8Array = new Uint8Array(binaryString.length);
                        for (let i = 0; i < binaryString.length; i++) {
                          uint8Array[i] = binaryString.charCodeAt(i);
                        }
                        const requestObject = {
                          method: "post",
                          credentials: "include",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                          },
                          body: JSON.stringify({
                            fileName: `${userName}/logokit/${e.target.files[0].name}`,
                            fileType: "image/png",
                          }),
                        };
                        await fetch(
                          `${api_Url}/flonnect/api/aws/generateThumbnailPreSignedPutUrl`,
                          requestObject
                        )
                          .then((response) => response.json())
                          .then((url) => {
                            if (url.signedUrl) {
                              fetch(url.signedUrl, {
                                method: "put",
                                body: uint8Array,
                                headers: {
                                  "Access-Control-Allow-Origin": "*",
                                },
                              }).then(async () => {
                                let apiBody = {

                                  ...brandKit,
                                  logo: [
                                    brandKit?.logo[0],
                                    {
                                      url: `https://video-sink-optimised.s3.ap-south-1.amazonaws.com/${userName}/logokit/${e.target.files[0].name}`,
                                      default: false,
                                    },
                                    {
                                      url: "",
                                      default: brandKit?.logo[0]?.default ? false : true,
                                    }
                                  ],

                                };
                                const apiObject = {
                                  method: "post",
                                  credentials: "include",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                  },
                                  body: JSON.stringify(apiBody),
                                };
                                updateBrandKit(apiObject)
                                  .then((data) => {
                                    let userBrandKitDetails =
                                      data?.userBrandKitDetails;
                                    brandkitDispatch({
                                      type: "FETCH_BRAND_KIT",
                                      data: userBrandKitDetails,
                                    });
                                    toast?.success('Logo added...')
                                    e.target.files = null
                                    brandkitDispatch({
                                      type: "HANDLE_INTRO_OUTRO_MENU",
                                      data: {
                                        open: !introOutroMenu?.open,
                                        id: "",
                                      },
                                    });
                                    toast.dismiss(toastId)
                                  })
                                  .catch((error) => {
                                    console.error("Error:", error);
                                    toast?.error('Something went wrong...')
                                    toast.dismiss(toastId)
                                  });
                              });
                            }
                          });
                      } else {

                        const requestObject = {
                          method: "post",
                          credentials: "include",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                          },
                          body: JSON.stringify({
                            fileName: `${userName}/logokit/${e.target.files[0].name}`,
                            fileType: e.target.files[0].type,
                          }),
                        };
                        await fetch(
                          `${api_Url}/flonnect/api/aws/generateThumbnailPreSignedPutUrl`,
                          requestObject
                        )
                          .then((response) => response.json())
                          .then((url) => {
                            if (url.signedUrl) {
                              fetch(url.signedUrl, {
                                method: "put",
                                body: e.target.files[0],
                                headers: {
                                  "Access-Control-Allow-Origin": "*",
                                },
                              }).then(async () => {
                                let apiBody = {

                                  ...brandKit,
                                  logo: [
                                    brandKit?.logo[0],
                                    {
                                      url: `https://video-sink-optimised.s3.ap-south-1.amazonaws.com/${userName}/logokit/${e.target.files[0].name}`,
                                      default: false,
                                    },
                                    {
                                      url: "",
                                      default: brandKit?.logo[0]?.default ? false : true,
                                    }
                                  ],

                                };
                                const apiObject = {
                                  method: "post",
                                  credentials: "include",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                  },
                                  body: JSON.stringify(apiBody),
                                };
                                updateBrandKit(apiObject)
                                  .then((data) => {
                                    let userBrandKitDetails =
                                      data?.userBrandKitDetails;
                                    brandkitDispatch({
                                      type: "FETCH_BRAND_KIT",
                                      data: userBrandKitDetails,
                                    });
                                    brandkitDispatch({
                                      type: "HANDLE_INTRO_OUTRO_MENU",
                                      data: {
                                        open: !introOutroMenu?.open,
                                        id: "",
                                      },
                                    });
                                    toast?.success('Logo added...')
                                    toast.dismiss(toastId)
                                  })
                                  .catch((error) => {
                                    console.error("Error:", error);
                                    toast?.error('Something went wrong...')
                                    toast.dismiss(toastId)
                                  });
                              });
                            }
                          });
                      }
                    };
                    img.src = event.target.result;
                  }
                  reader.readAsDataURL(e.target.files[0]);


                }
              }}
              ref={inputFile}
            />
          </UploadLogoWrap>
          <DefaultWrap
            onClick={() => {
              if (brandKit?.logo[0]?.url) {
                let apiBody = {

                  ...brandKit,
                  logo: [
                    {
                      url: brandKit?.logo[0]?.url,
                      default: true
                    }
                    ,
                    {
                      url: brandKit?.logo[1]?.url,
                      default: false,
                    },
                    {
                      url: "",
                      default: false
                    }
                  ],

                };
                const apiObject = {
                  method: "post",
                  credentials: "include",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify(apiBody),
                };
                updateBrandKit(apiObject)
                  .then((data) => {
                    let userBrandKitDetails =
                      data?.userBrandKitDetails;
                    brandkitDispatch({
                      type: "FETCH_BRAND_KIT",
                      data: userBrandKitDetails,
                    });
                    toast?.success('Logo updated...')
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    toast?.error('Something went wrong...')
                  });
              }

            }}>
            <DefaultLogo bord={brandKit?.logo[0]?.default}>
              <img src={Logo} />
            </DefaultLogo>
            <SizeWrap>
              {brandKit?.logo[0]?.default ? (
                <RadioChecked>
                  <div />
                </RadioChecked>
              ) : (
                <RadioBtn />
              )}

              <SizeText>{brandKit?.logo[0]?.default ? 'Default' : 'Mark as Default'}</SizeText>
            </SizeWrap>
          </DefaultWrap>
          {brandKit?.logo?.length > 1 && (


            <DefaultWrap
            >
              {brandKit?.logo[1]?.url && (
                <>
                  <DefaultLogo bord={brandKit?.logo[1]?.default} style={{ position: "relative" }} >
                    <img src={brandKit?.logo[1]?.url} className="log-kit" onClick={() => {
                      let apiBody = {

                        ...brandKit,
                        logo: [
                          {
                            url: brandKit?.logo[0]?.url,
                            default: false
                          }
                          ,
                          {
                            url: brandKit?.logo[1]?.url,
                            default: true,
                          },
                          {
                            url: "",
                            default: false,
                          }
                        ],

                      };
                      const apiObject = {
                        method: "post",
                        credentials: "include",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(apiBody),
                      };
                      updateBrandKit(apiObject)
                        .then((data) => {
                          let userBrandKitDetails =
                            data?.userBrandKitDetails;
                          brandkitDispatch({
                            type: "FETCH_BRAND_KIT",
                            data: userBrandKitDetails,
                          });
                          toast?.success('Logo updated...')
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                          toast?.error('Something went wrong...')
                        });
                    }} />
                    <IntroOutroKebab onClick={(e) => {
                      brandkitDispatch({
                        type: "HANDLE_INTRO_OUTRO_MENU",
                        data: {
                          open: !introOutroMenu?.open,
                          id: "logo",
                        },
                      });
                    }}>
                      <img src={brandKitOptions} draggable={false} />

                    </IntroOutroKebab>
                    {introOutroMenu?.open && introOutroMenu?.id === "logo" && (
                      <BrandKitKebab type="logo" />
                    )}
                  </DefaultLogo>
                  <SizeWrap>
                    {brandKit?.logo[1]?.default ? (
                      <RadioChecked>
                        <div />
                      </RadioChecked>
                    ) : (
                      <RadioBtn />
                    )}
                    <SizeText>{brandKit?.logo[1]?.default ? 'Default' : 'Mark as Default'}</SizeText>
                  </SizeWrap>
                </>
              )}

            </DefaultWrap>
          )}
          <DefaultWrap onClick={() => {
            let apiBody;
            apiBody = {
              ...brandKit,
              logo: [
                {
                  url: brandKit?.logo[0]?.url,
                  default: false,
                }
                ,
                {
                  url: brandKit?.logo[1]?.url ? brandKit?.logo[1]?.url : "",
                  default: false,
                },
                {
                  url: "",
                  default: true
                }
              ],
            }


            const apiObject = {
              method: "post",
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify(apiBody),
            };
            updateBrandKit(apiObject)
              .then((data) => {
                let userBrandKitDetails =
                  data?.userBrandKitDetails;
                brandkitDispatch({
                  type: "FETCH_BRAND_KIT",
                  data: userBrandKitDetails,
                });
                toast?.success('Logo updated...')
              })
              .catch((error) => {
                console.error("Error:", error);
                toast?.error('Something went wrong...')
              });
          }}>

            <UploadLogoSec>
              <SizeText>No Logo</SizeText>
            </UploadLogoSec>
            <SizeWrap>
              {brandKit?.logo?.length > 1 ? (brandKit?.logo[brandKit?.logo?.length - 1]?.default ? (
                <RadioChecked>
                  <div />
                </RadioChecked>
              ) : (
                <RadioBtn />
              )) : (
                <RadioBtn />
              )}
              <SizeText>{brandKit?.logo?.length > 1 ? (brandKit?.logo[brandKit?.logo?.length - 1]?.default ? 'Default' : 'Mark as Default') : "Mark as Default"}</SizeText>
            </SizeWrap>
          </DefaultWrap>

        </LogoUploadWrap>
      </ItemRHS>
      <BrandkitPreview type='logo' />
    </ItemWrap>
  );
};

export default LogoKit;

import { createContext, useContext, useReducer, useRef } from "react";
import _static from "../../../../config/static";

const BrandkitContext = createContext(null);

const initialState = {
  backgroundTheme: [],
  bgColor: {
    open: false,
    selected: "",
  },
  callout: {
    open: ""
  },
  fontMenu: {
    open: false
  },
  fontFamily: {
    open: false
  },

  brandKit: {
    introVideo: [],
    outroVideo: [],
    logo: [],
    font: {},
    backgroundTheme: [],
    callout: {
      backgroundColor: "#FF5733",
      textColor: "#FF5733",
      buttonColor: "#FF5733"
    }

  },
  introOutroMenu: {
    open: false,
    id: "",
  }
};

const BrandKitReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_BRAND_KIT":
      return {
        ...state,
        brandKit: action?.data,
        bgColor: {
          open: false,
          selected: action?.data?.backgroundTheme?.length > 1 ? action?.data?.backgroundTheme[1]?.color : ''
        },

      }
    case "HANDLE_HEADING_FONT":
      return {
        ...state,

        fontMenu: {
          open: action?.data
        },
      };
    case "HANDLE_BG_COLOR_PICKER":
      return {
        ...state,
        bgColor: {
          ...state?.bgColor,
          open: action?.data,
        },
      };
    case "HANDLE_BG_COLOR":
      return {
        ...state,
        bgColor: {
          ...state?.bgColor,
          selected: action?.data,
        },
      };
    case "HANDLE_CALLOUT":
      return {
        ...state,
        callout: {
          open: action?.data,
        },
      };
    case "HANDLE_CALLOUT_COLOR":
      return {
        ...state,
        brandKit: {
          ...state?.brandKit,
          callout: {
            ...state?.callout,
            backgroundColor: action?.data?.bgColor,
            textColor: action?.data?.textColor,
            buttonColor: action?.data?.btnColor,
          }
        }
        ,
      };
    case "HANDLE_FONT_FAMILY_MENU":
      return {
        ...state,
        fontFamily: {
          open: action?.data
        },
      }
    case "HANDLE_FONT_FAMILY":
      return {
        ...state,
        brandKit: {
          ...state?.brandKit,
          font: { ...state?.brandKit?.font, fontFamily: action?.data },
        },
      };
    case "HANDLE_INTRO_OUTRO_MENU":
      return {
        ...state,
        introOutroMenu: {
          open: action?.data?.open,
          id: action?.data?.id,
        }
      };
  }

};

const useBrandKitReducer = () => {
  return useReducer(BrandKitReducer, initialState);
};

export const BrandKitProvider = ({ children }) => {
  const [state, dispatch] = useBrandKitReducer();
  const introFileRef = useRef(null)
  const outroFileRef = useRef(null)
  const { capt_Url } = _static
  const updateBrandKit = async (apiBody) => {
    try {
      const response = await fetch(`${capt_Url}/api/capture/brandkit/updatebrandkit`, apiBody);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Fetch error:', error);
      return error;
    }
  };
  const uploadInOutVideoBrandKit = async (
    files,
    fileName,
    type, url
  ) => {

    return new Promise(async (resolve, reject) => {
      try {
        const uploadResponse = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': files.type,
          },
          body: files,
        });
        if (uploadResponse?.ok) {
          resolve(uploadResponse?.url.split("?")[0])
        } else {
          reject(null)
        }

      } catch (e) {
        reject(null)
      }
    })

  };
  return (
    <BrandkitContext.Provider
      value={{
        brandkitState: state,
        brandkitDispatch: dispatch,
        updateBrandKit,
        introFileRef,
        outroFileRef,
        uploadInOutVideoBrandKit
      }}
    >
      {children}
    </BrandkitContext.Provider>
  );
};

export const useBrandKit = () => {
  return useContext(BrandkitContext);
};

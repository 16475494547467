import styled from "styled-components";
import theme from "../../theme";

export const LoginCnt = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* height:100vh;
 background-color: #E7EAFD; */

  /* height: 1042px;
 width: 1440px; */
  width: 100%;
  height: 100%;
  padding: 26px 26px 25px 20px;
  justify-content: space-between;
`;
export const LeftSec = styled.div`
  width: 49%;
  height: 100%;
  /* background-color: yellow; */

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-img {
    width: 138.345px;
    height: 32px;
    align-self: flex-start;
  }

  @media (max-width: 1200px) {
    width: 100%;
    
  }
  @media (max-height: 580px) {
    width: 100%;
    justify-content: center;
  }

  /* @media (max-width:1600px){
    width:60%;
    img{
        width:80%;
    }
}
@media (max-width:989px){
    width:50%;
    img{
        width:90%;
    }
} */
`;
export const RightSec = styled.div`
  background-color: white;
  height: 100%;
  width: 49.7%;
  border-radius: 20px;
  background: #f7f8fa;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  /* @media (max-width:1600px){
    width:40%;
}
@media (max-width:989px){
    width:50%;
} */

  @media (max-width: 1200px) {
    display: none;
  }
  @media (max-height: 580px) {
    display: none;
  }
`;
export const RightIndiv = styled.div`
  /* width:80%; */
  width: 54.1%;
  width: 360px;
  margin: 17% auto 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  /* background-color: red; */

  .signin {
    margin-top: 18.5%;
    font-weight: 600;
    font-family: "Inter";
    font-style: normal;
    line-height: 30px;
    font-size: 22px;
  }
  .term {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12.9211px;
    line-height: 22px;
    letter-spacing: 0.323026px;
    color: #1a1a1a;
  }
  .line {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 9% !important;
  }
  #signInDiv {
    margin-top: 11% !important;
    margin-bottom: 9% !important;
  }

  @media (max-height: 770px) {
    margin: 10% auto;
  }
  @media (max-height: 660px) {
    margin: auto;
  }
`;
export const GoogleBtn = styled.div`
  background-color: #333333;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6.5px;
  padding: 2.5% 0;
  margin-top: 11% !important;
  margin-bottom: 18% !important;
`;
export const WelcomeText = styled.div`
  /* margin-top: 15%;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 21.5351px;
line-height: 30px;
color: #1A1A1A;
text-align:start; */

  color: ${theme.colors.lightText1};
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
`;
export const LoginFillText = styled.div`
  color: ${theme.colors.placeHolder};
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 8px;
  margin-bottom: 19%;
 @media (max-width: 700px) {
    margin-bottom: 0%;
  }
  @media (max-height: 770px) {
    margin-bottom: 4%;
  }
  @media (max-height: 660px) {
    margin-bottom: 0%;
  }
`;
export const FormWrap = styled.form``;
export const SignupFormWrap = styled.form`
  display: flex;
  flex-direction: column;
  /* background-color: red; */

  .name-wrapper {
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NameField = styled.div`
  margin-top: 6%;

  .name-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const SignUpField = styled.div`
  margin-top: 6%;
`;

export const LoginPgQuote = styled.div`
  color: ${theme.colors.placeHolder};
  text-align: left;
  margin-bottom: 15px;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  /* width: 448px;
margin-right: 50px; */
`;
export const LoginPgDesc = styled.div`
  /* width: 65.6%; */
  text-align: left;
  /* margin-right: 7%; */
  /* background-color: red; */

  /* color: rgba(64, 75, 89, 0.60); */
  color: ${(props) => props.clr};
  opacity: 0.6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.389px; /* 145.639% */

  margin-right: 20px;

  /* width: 448px;
margin-right: 50px; */
`;

export const LoginPgImg = styled.img`
  /* margin-top: 18%; */
  height: 100%;
  width: auto;

  /* @media (max-height:820px){
    margin-top: 7%;

} */

  /* @media (max-width:1600px){
    width:60%;
    img{
        width:80%;
    }
}

/* width: 1547px;
height: 1078.694px; */
`;

export const InputName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.1513px;
  line-height: 22px;
  color: #1a1a1a;
  text-align: start;
  margin-left: 3%;
`;

export const InputEmail = styled.input`
  background: #f2f2f2;
  margin-top: 2%;
  padding: 3.5%;
  border: 0.538377px solid #e5e5e5;
  border-radius: 6.46053px;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1513px;
  line-height: 22px;
  color: #1a1a1a;
  &.error-msg {
    border: 0.538377px solid #ff3b30;
    color: #ff3b30;
  }
  &:focus {
    border: 1px solid #007aff;
    outline: none;
  }
`;
export const SignupEmail = styled.input`
  background: #f2f2f2;
  margin-top: 2%;
  padding: 3.5%;
  border: 0.538377px solid #e5e5e5;
  border-radius: 6.46053px;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1513px;
  line-height: 22px;
  color: #1a1a1a;
  &.error-msg {
    border: 0.538377px solid #ff3b30;
    color: #ff3b30;
  }
  &:focus {
    border: 1px solid #007aff;
    outline: none;
  }
`;
export const InputOtp = styled.input`
  background: #f2f2f2;
  margin-top: 2%;
  padding: 3.5%;
  border: 0.538377px solid #e5e5e5;
  border-radius: 6.46053px;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1513px;
  line-height: 22px;
  color: #1a1a1a;
  &.error-msg {
    border: 0.538377px solid #ff3b30;
    color: #ff3b30;
  }
  &:focus {
    border: 1px solid #007aff;
    outline: none;
  }
`;

export const InputPass = styled.input`
  background: #f2f2f2;
  margin-top: 2%;
  padding: 3.5%;
  border: 0.538377px solid #e5e5e5;
  border-radius: 6.46053px;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1513px;
  line-height: 22px;
  color: #1a1a1a;
  &.error-msg {
    border: 0.538377px solid #ff3b30;
    color: #ff3b30;
  }
  &:focus {
    border: 1px solid #007aff;
    outline: none;
  }
`;

export const PasswordField = styled.div`
  margin-top: 4%;

  .pass-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .name-wrapper {
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PasswordName = styled.div`
  /* font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 11.8443px;
line-height: 13px;
letter-spacing: 0.323026px;
color: #333333; 
text-align:start;
margin-left: 3%; */

  color: ${theme.colors.lightText1};

  /* Text 2 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 8px;
`;
export const EmailName = styled.div`
  /* font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 11.8443px;
line-height: 13px;
letter-spacing: 0.323026px;
color: #333333; 
text-align:start;
margin-left: 3%; */

  color: ${theme.colors.lightText1};
  display: flex;
  justify-content: flex-start;

  /* Text 2 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 8px;
`;

export const PassEye = styled.div`
  position: relative;

  .pass-eye {
    position: absolute;
    right: 6%;
    top: 10px;
    cursor: pointer;
  }
`;

export const SignButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  background: #007aff;
  border-radius: 7px;
  border: 1px solid #007aff;
  padding: 12px;
  text-align: center;
  letter-spacing: 0.323026px;
  width: 100%;
  border-radius: 8px;
  background-color: ${theme.colors.button};

  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  border: none;

  &.but-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const LineDiv = styled.div`
  border-top: 1px solid #e5e5e5;
  margin-top: 8%;
`;

export const GoogleDiv = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignNew = styled.div`
  margin-left: 2%;
  color: #5429ff;
  cursor: pointer;
  font-weight: 500;
`;

export const RememberMe = styled.div`
  /* margin-top: 4%; */
  display: flex;
  justify-content: flex-end;

  .flex-div {
    display: flex;
    align-items: center;
    color: ${theme.colors.lightText1};

    /* Text 2 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .flex-div input {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
  }
`;

export const ToggleParent = styled.div`
  background: #f2f2f2;
  border: 0.538377px solid #e5e5e5;
  border-radius: 39.3015px;
  width: 43px;
  height: 21px;
  position: relative;
  padding: 1px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.9211px;
  line-height: 22px;
  letter-spacing: 0.323026px;
  color: #1a1a1a;
`;

export const ToggleChild = styled.div`
  background: #ffffff;
  box-shadow: 1.07675px 1.07675px 2.15351px -1.07675px rgba(51, 51, 51, 0.3);
  border-radius: 12.9211px;
  position: absolute;
  width: 17px;
  height: 17px;
  cursor: pointer;
  transition: 0.4s;
`;

export const ToggleText = styled.div`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.323026px;
  color: #1a1a1a;
`;

export const ForgotPassText = styled.div`
  /* font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12.9211px;
line-height: 22px;
letter-spacing: 0.323026px; */
  /* color: #007AFF; */
  cursor: pointer;

  color: ${theme.colors.button};

  /* Text 2 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const SecondPage = styled.div`
  /* background-color: red; */
  width: 410px;
  margin: 17% auto;
  display: flex;
  flex-direction: column;

  .logo-img {
    width: 42.5%;
  }
`;

export const WrapperRightSide = styled.div`
  width: 73%;
  height: 73.2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .loginPgImgWrapper {
    display: flex;
    justify-content: flex-end;
    height: 76%;
  }

  @media (max-height: 770px) {
    height: 85%;
  }
`;

export const ResetText = styled.div`
  /* margin-top: 13.5%; 
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 21.5351px;
line-height: 30px;
color: #1A1A1A;
text-align:start; */

  color: #344054;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: start;
`;

export const PassPara = styled.div`
  margin-top: 8px;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11.8443px;
  line-height: 13px;
  /* color: #333333; */
  /* margin-left: 4%; */
  /* width: 80%; */

  color: ${theme.colors.placeHolder};

  /* Text 1 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 68px;
`;

export const InputEmail2 = styled(InputEmail)`
  margin-top: 5%;
`;

export const ResetButton = styled(SignButton)`
  width: 360px;
  margin-top: 52px;
  &.but-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const ThirdPage = styled.div`
  width: 80%;
  margin: 10% auto;
  display: flex;
  flex-direction: column;

  .logo-img {
    width: 42.5%;
  }

  .flex-div {
    display: flex;
  }
`;

export const NewAccountHeading = styled(WelcomeText)``;

export const InputNameFirst = styled.input`
  background: #f2f2f2;
  margin-top: 2%;
  padding: 3.5%;
  border: 0.538377px solid #e5e5e5;
  border-radius: 6.46053px;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1513px;
  line-height: 22px;
  color: #1a1a1a;
  &.error-msg {
    border: 0.538377px solid #ff3b30;
    color: #ff3b30;
  }
  &:focus {
    border: 1px solid #007aff;
    outline: none;
  }
`;
export const LastNameWrap = styled.div`
  width: 50%;
  margin-top: 46px;
`;

export const InputNameLast = styled.input`
  background: #f2f2f2;
  margin-top: 2%;
  padding: 6.5%;
  border: 0.538377px solid #e5e5e5;
  border-radius: 6.46053px;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16.1513px;
  line-height: 22px;
  color: #1a1a1a;
  &.error-msg {
    border: 0.538377px solid #ff3b30;
    color: #ff3b30;
  }
  &:focus {
    border: 1px solid #007aff;
    outline: none;
  }
`;
export const NewAccount = styled.div`
  margin-top: 20px;
  display: flex;
  /* color: #1A1A1A; */
  align-items: center;
  justify-content: center;
  /* font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12.9211px;
line-height: 22px;
letter-spacing: 0.323026px; */

  color: ${theme.colors.lightText1};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

export const ErrorMsg = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11.8443px;
  line-height: 13px;
  letter-spacing: 0.323026px;
  color: #ff3b30;
  margin: 8px;
  display: flex;
  justify-content: flex-start;
`;
export const ResendCode = styled.div`
  margin-top: 4.5%;
  display: flex;
  color: #1a1a1a;
  align-items: center;
  justify-content: flex-end;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.9211px;
  line-height: 22px;
  letter-spacing: 0.323026px;
`;

export const NewPasswordWrap = styled.div`
  /* margin-top:40px; */
`;

export const LoginLogo = styled.img``;

export const InputField = styled.input`
  width: 360px;
  height: 44px;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  display: flex;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 10px 20px 10px 14px;
  margin-bottom: 20px;
  color: ${theme.colors.placeHolder};
  &::placeholder {
    color: rgba(102, 112, 133, 0.5);
    opacity: 0.5;
  }
  &.error-msg {
    border: 0.538377px solid #ff3b30;
    color: #ff3b30;
  }
  &:focus {
    border: 1px solid #5429ff;
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    -webkit-text-fill-color: ${theme.colors.placeHolder} !important;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    -webkit-text-fill-color: ${theme.colors.placeHolder} !important;
  }
`;
export const RememberPassword = styled.div`
  width: 360px;
  margin-top: 5%;
  color: ${theme.colors.lightText1};
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  span {
    cursor: pointer;
    color: #5429ff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

import React from 'react';
import { BrandItemsWrap } from '../../brandkit-styled';
import LogoKit from '../LogoKit/logoKit';
import BackGroundKit from '../BackGroundKit/backGroundKit';
import IntroKit from '../IntroKIt/introKit';
import OutroKit from '../OutroKit/outroKit';
import FontKit from '../FontKit/fontKit';
import VoiceKit from '../VoiceKit/voiceKit';
import CalloutKit from '../CalloutKit/calloutKit';

const BrandItems = () => {
    return (
        <BrandItemsWrap>
            <LogoKit/>
            <BackGroundKit/>
            <IntroKit/>
            <OutroKit/>
            <FontKit/>
            {/* <VoiceKit/> */}
            <CalloutKit/>
        </BrandItemsWrap>
    );
};

export default BrandItems;
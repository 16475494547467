import React from "react";
import { ItemHeadWrap, ItemMainHead, ItemSubHead } from "../brandkit-styled";

const KitItemHead = (props) => {
  const { head, subHead } = props;
  return (
    <ItemHeadWrap>
      <ItemMainHead>{head}</ItemMainHead>
      <ItemSubHead>{subHead}</ItemSubHead>
    </ItemHeadWrap>
  );
};

export default KitItemHead;


import './App.css';
import { AuthProvider } from './context/auth';
import { ThemeProvider } from 'styled-components'
import AppRoute from './components';
import HeaderRoot from './components/Header/headerRoot';
import ChatBot from './chat';
import mixpanel from 'mixpanel-browser';
import theme from './theme';
import _static from './config/static';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast'
mixpanel.init(_static.mixpanel_Token);
const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider >
        <ThemeProvider theme={theme}>
          <div className="App">
            <AppRoute />
            {
           (!window?.location?.href?.includes ("video/embed/") && !window?.location?.href?.includes ("/htmlstep"))&&
            <ChatBot/>
            }
          </div>
          <Toaster />
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;

import React from "react";
import {
  BrandHeadMainText,
  BrandHeadTextWrap,
  BrandHeadWrap,
  BrandSubText,
  BrandSubTextCont,
  BrandSubTextWrap,
  ClickIcon,
  HeadImg,
} from "../../brandkit-styled";
import tick from '../../Images/tick.svg'
import head from '../../Images/head.svg'

const BrandHead = () => {
  return (
    <BrandHeadWrap>
      <BrandHeadTextWrap>
        <BrandHeadMainText>
          Make every video a True Representation of your Brand
        </BrandHeadMainText>
        <BrandSubTextWrap>
          <BrandSubTextCont>
            <ClickIcon src={tick} draggable={false} alt="no-icon"/>
            <BrandSubText>
              Create perfectly branded videos with ease—customize fonts, colors,
              and more using our Brand Kit.
            </BrandSubText>
          </BrandSubTextCont>
          <BrandSubTextCont>
            <ClickIcon src={tick} draggable={false} alt="no-icon" />
            <BrandSubText>
              With our Brand Kit, tailor fonts, colors, and styles to resonate
              with your audience
            </BrandSubText>
          </BrandSubTextCont>
        </BrandSubTextWrap>
      </BrandHeadTextWrap>
      <HeadImg src={head}/>
    </BrandHeadWrap>
  );
};

export default BrandHead;

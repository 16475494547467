import React from "react";
import { BlackScreen } from "../../VideoPlayer.styled";
import rotate from "../../images/rotate.svg";

const WatchAgain=(props)=>{
    const {Reset}=props;
    return(
        <BlackScreen>
        <div onClick={Reset}>
          <img className="reloadIcon" src={rotate} draggable="false"  alt=""/>
          <p>Watch Again</p>
        </div>
      </BlackScreen>
    )
}
export default WatchAgain;
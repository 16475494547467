import React, { useEffect, useRef, useState } from 'react'
import { ArrowButtonDiv, BrandLogoDiv, ButtonDiv, DescContParaDiv, DescContPrev, DescHeadingPrev, IntroVideo, OutroVideo, PreviewBgCont, PreviewImageDiv, StepNumberText, TotalDescCont, TotalPreviewContBrand } from '../../brandkit-styled'
import Logo from "../../Images/logo.svg";
import { useBrandKit } from '../../context/brandContext';
import DotImage from './dotImage';
import StepArrowPrev from './stepArrow';
import NextArrowPrev from './nextArrowPrev';
let previousWidth;
let previousHeight;
const BrandkitPreview = (props) => {
    const { type } = props
    const { brandkitState, brandkitDispatch, updateBrandKit, introFileRef, uploadInOutVideoBrandKit } = useBrandKit()
    const { brandKit, introOutroMenu } = brandkitState
    const imageRef = useRef(null);
    const parentDivRef = useRef(null);
    const [logoCord, setLogoCoord] = useState({
        x: "",
        y: "",
    })
    const [bgCord, setBgCord] = useState({
        x: "",
        y: ""
    })
    useEffect(() => {
        const handleResize = () => {
            if (imageRef.current) {
                const divWidth = imageRef.current.offsetWidth;
                const divHeight = imageRef.current.offsetHeight;
                // let tempPreviousWidth = previousWidth;
                // previousWidth = divWidth;
                // let tempPreviousHeight = previousHeight;
                // previousHeight = divHeight;

                let x = divWidth / parentDivRef?.current?.offsetWidth;
                let y = divHeight / parentDivRef?.current?.offsetHeight

                if (type === "logo") {
                    let mainX = x * 98;
                    let mainY = y * 55;
                    if (mainX !== Infinity && mainY !== Infinity) {
                        setLogoCoord({ x: mainX, y: mainY })
                    }
                }

                if (type === "background") {
                    let mainX = x * 102;
                    let mainY = y * 50;
                    if (mainX !== Infinity && mainY !== Infinity) {
                        setBgCord({ x: mainX, y: mainY })
                    }
                }



            }
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (imageRef.current) {
            resizeObserver.observe(imageRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [imageRef?.current?.offsetWidth]);
    return (
        <TotalPreviewContBrand ref={parentDivRef}>
            <PreviewImageDiv type={type} ref={imageRef} introVideo={brandKit?.introVideo} outroVideo={brandKit?.outroVideo}>
                {type === "logo" ? (
                    (() => {
                        let el = brandKit?.logo.filter((each) => each?.default)
                        return (
                            <BrandLogoDiv style={{ left: logoCord?.x, top: logoCord?.y }}>
                                <img src={el[0]?.url} draggable={false} />
                            </BrandLogoDiv>
                        )
                    })()
                ) : (

                    (type === "background" && brandKit?.backgroundTheme[1]?.default) ? (
                        <PreviewBgCont bg={brandKit?.backgroundTheme[1]?.color} style={{ left: bgCord?.x, top: bgCord?.y }}>

                        </PreviewBgCont>
                    ) : (
                        (type === "intro" && brandKit?.introVideo) ? (
                            <IntroVideo src={brandKit?.introVideo[0]?.url}>

                            </IntroVideo>
                        ) : (
                            (type === "outro" && brandKit?.outroVideo) ? (
                                <OutroVideo src={brandKit?.outroVideo[0]?.url}>

                                </OutroVideo>
                            ) : (
                                type === "font" ? (
                                    <TotalDescCont>
                                        <DotImage bg={"#E23B66"} />
                                        <StepArrowPrev bg={"#E23B66"} />
                                        <DescContPrev bg={"#E23B66"}>
                                            <DescHeadingPrev bg={"#ffffff"} font={brandKit?.font?.fontFamily}>Search History</DescHeadingPrev>
                                            <DescContParaDiv bg={"#ffffff"} font={brandKit?.font?.fontFamily}>Hi Hello vanakam. This is santhosh
                                                from TN. new design work is going on
                                                full speed. all the best. </DescContParaDiv>
                                            <ButtonDiv>
                                                <StepNumberText bg={"#ffffff"} font={brandKit?.font?.fontFamily}>1 of 10</StepNumberText>
                                                <ArrowButtonDiv bg={"#6528F7"}>
                                                    <NextArrowPrev />
                                                </ArrowButtonDiv>
                                            </ButtonDiv>
                                        </DescContPrev>
                                    </TotalDescCont>
                                ) : (
                                    type === "callout" && (
                                        <TotalDescCont>
                                            <DotImage bg={brandKit?.callout?.backgroundColor} />
                                            <StepArrowPrev bg={brandKit?.callout?.backgroundColor} />
                                            <DescContPrev bg={brandKit?.callout?.backgroundColor}>
                                                <DescHeadingPrev bg={brandKit?.callout?.textColor} font={brandKit?.font?.fontFamily}>Search History</DescHeadingPrev>
                                                <DescContParaDiv bg={brandKit?.callout?.textColor} font={brandKit?.font?.fontFamily}>Hi Hello vanakam. This is santhosh
                                                    from TN. new design work is going on
                                                    full speed. all the best. </DescContParaDiv>
                                                <ButtonDiv>
                                                    <StepNumberText bg={brandKit?.callout?.textColor} font={brandKit?.font?.fontFamily}>1 of 10</StepNumberText>
                                                    <ArrowButtonDiv bg={brandKit?.callout?.buttonColor}>
                                                        <NextArrowPrev />
                                                    </ArrowButtonDiv>
                                                </ButtonDiv>
                                            </DescContPrev>
                                        </TotalDescCont>
                                    )
                                )
                            )
                        )

                    )

                )}
            </PreviewImageDiv>

        </TotalPreviewContBrand>
    )
}

export default BrandkitPreview

import React from "react";
import { Helmet } from "react-helmet";
import { useAuth } from "./context/auth";
const ChatBot = (props) => {
  const auth = useAuth()
  return (
    <>
      <Helmet>
      <script>
 { `function initFreshChat() {
    
    window.fcWidget?.setExternalId("${auth?.authState?.user?.email?auth?.authState?.user?.email:""}");

    window.fcWidget.user.setFirstName("${auth?.authState?.user?.username?auth?.authState?.user?.username:"unknown"}");
// To set user email
window.fcWidget?.user.setEmail("${auth?.authState?.user?.email?auth?.authState?.user?.email:""}");
  }
  function initialize(i,t){var e;i.getElementById(t)?
  initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
  e.src="https://flonnect.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
  function initiateCall(){initialize(document,"Freshchat-js-sdk")}
  window.addEventListener?window.addEventListener("load",initiateCall,!1):
  window.attachEvent("load",initiateCall,!1);`}
</script>
<script
src='//in.fw-cdn.com/31958270/1029503.js'
chat='true'>
</script>
      </Helmet>
    </>
  );
};

export default ChatBot;

import React from "react";
import {
  BackGroundWrap,
  ChangeBgSec,
  ChangeBgWrap,
  DefaultBg,
  DefaultWrap,
  ItemRHS,
  ItemWrap,
  RadioBtn,
  RadioChecked,
  SizeText,
  SizeWrap,
} from "../../brandkit-styled";
import KitItemHead from "../kitItemHead";
import AddColor from "../../Images/addColor.svg";
import Info from "../../Images/info.svg";
import DefBg from "../../Images/defaultBg.svg";
import { useBrandKit } from "../../context/brandContext";
import ColorPicker from "../ColorPicker/colorPicker";
import { toast } from "react-hot-toast";
import BrandkitPreview from "../BrandKitPreview/brandkitPreview";
const BackGroundKit = () => {
  const { brandkitState, updateBrandKit, brandkitDispatch } = useBrandKit();
  const { bgColor, brandKit } = brandkitState;
  const handleColorChange = (newColor) => {
    brandkitDispatch({
      type: "HANDLE_BG_COLOR",
      data: newColor?.hex,
    });
  };
  const handleCancel = () => {
    if (bgColor?.open) {
      brandkitDispatch({
        type: "HANDLE_BG_COLOR_PICKER",
        data: false,
      });
    }
  };
  const saveFn = async () => {
    let apiBody = {
      ...brandKit,
      backgroundTheme: [
        brandKit?.backgroundTheme[0],
        {
          color: bgColor?.selected,
          default: brandKit?.backgroundTheme[1]?.default,
        },
      ],
    };
    const apiObject = {
      method: "post",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(apiBody),
    };
    updateBrandKit(apiObject)
      .then((data) => {
        let userBrandKitDetails =
          data?.userBrandKitDetails;
        brandkitDispatch({
          type: "FETCH_BRAND_KIT",
          data: userBrandKitDetails,
        });
        toast?.success('Background updated...')
      })
      .catch((error) => {
        console.error("Error:", error);
        toast?.success('Something went wrong...')
      });
  };
  return (
    <ItemWrap mt={true}>
      <ItemRHS>
        <KitItemHead
          head={"Background Theme"}
          subHead={
            "Your background theme is the canvas for your brand's story."
          }
        />

        <BackGroundWrap>
          <ChangeBgWrap>
            <ChangeBgSec
              onClick={() => {
                brandkitDispatch({
                  type: "HANDLE_BG_COLOR_PICKER",
                  data: true,
                });
              }}
            >
              <img src={AddColor} />
              <SizeText>Add Bg Theme</SizeText>
              {bgColor?.open && (
                <ColorPicker
                  cancelFn={handleCancel}
                  handleColorChange={handleColorChange}
                  color={bgColor?.selected}
                  saveFn={saveFn}
                />
              )}
            </ChangeBgSec>
          </ChangeBgWrap>
          <DefaultWrap
            onClick={() => {
              let apiBody = {

                ...brandKit,
                backgroundTheme: [
                  {
                    color: brandKit?.backgroundTheme[0]?.color,
                    default: true
                  }
                  ,
                  {
                    color: brandKit?.backgroundTheme[1]?.color,
                    default: false,
                  }
                ],

              };
              const apiObject = {
                method: "post",
                credentials: "include",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(apiBody),
              };
              updateBrandKit(apiObject)
                .then((data) => {
                  let userBrandKitDetails =
                    data?.userBrandKitDetails;
                  brandkitDispatch({
                    type: "FETCH_BRAND_KIT",
                    data: userBrandKitDetails,
                  });
                  toast?.success('Background updated...')
                })
                .catch((error) => {
                  console.error("Error:", error);
                  toast?.success('Something went wrong...')
                });
            }} >
            <DefaultBg bord={brandKit?.backgroundTheme[0]?.default}>
              <img src={DefBg} />
            </DefaultBg>
            <SizeWrap>
              {brandKit?.backgroundTheme[0]?.default ? (
                <RadioChecked>
                  <div />
                </RadioChecked>
              ) : (
                <RadioBtn />
              )}

              <SizeText>{brandKit?.backgroundTheme[0]?.default ? 'Default' : 'Mark as Default'}</SizeText>
            </SizeWrap>
          </DefaultWrap>
          {bgColor?.selected && (
            <DefaultWrap
              onClick={() => {
                let apiBody = {

                  ...brandKit,
                  backgroundTheme: [
                    {
                      color: brandKit?.backgroundTheme[0]?.color,
                      default: false
                    }
                    ,
                    {
                      color: brandKit?.backgroundTheme[1]?.color,
                      default: true,
                    }
                  ],

                };
                const apiObject = {
                  method: "post",
                  credentials: "include",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify(apiBody),
                };
                updateBrandKit(apiObject)
                  .then((data) => {
                    let userBrandKitDetails =
                      data?.userBrandKitDetails;
                    brandkitDispatch({
                      type: "FETCH_BRAND_KIT",
                      data: userBrandKitDetails,
                    });
                    toast?.success('Background updated...')
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    toast?.success('Something went wrong...')
                  });
              }}>
              <DefaultBg bg={bgColor?.selected} bord={brandKit?.backgroundTheme[1]?.default}></DefaultBg>
              <SizeWrap>
                {brandKit?.backgroundTheme[1]?.default ? (
                  <RadioChecked>
                    <div />
                  </RadioChecked>
                ) : (
                  <RadioBtn />
                )}
                <SizeText>{brandKit?.backgroundTheme[1]?.default ? 'Default' : 'Mark as Default'}</SizeText>
              </SizeWrap>
            </DefaultWrap>
          )}
        </BackGroundWrap>
      </ItemRHS>
      <BrandkitPreview type="background"/>
    </ItemWrap>
  );
};

export default BackGroundKit;

import styled from "styled-components";
import theme from "../../../theme";
export const HomeContainer = styled.div`
  * {
    font-family: "Inter", sans-serif;
  }
  background-color: white;
  position: relative;
  .black {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: ${(props) => (props.side ? "110px" : "236px")};
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.5);
    display: ${(props) =>
      (props.active === "Trim" && !props.side) ||
      (props.active === "Edit" && !props.side) ||
      (props.active === "Video" && !props.side)
        ? "block"
        : "none"};
    @media (max-width: 1100px) {
      display: block;
    }
  }
`;
export const Content = styled.div`
  width: 100%;
  height: ${(props) => (!props.bfsHead ? "95%" : "100%")};;
  display: flex;
  position: relative;
  flex-direction: column;
`;
export const BfsSpecial = styled.div`
  width: 100%;
 height:5%;
 background: #1A1A1A;
 display:flex;
 justify-content:center;
 align-items:center;
 gap:15px;
 ${theme.breakpoints.smallView} {
  gap:7px;
};
 position:relative;
`;
export const BfsStars = styled.img`
width: 24px;
height: 24px;
${theme.breakpoints.smallView} {
  display: none;
};
`
export const BfsHead = styled.div`
color: #FFF;
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 17.194px; /* 107.463% */
${theme.breakpoints.mediumView} {
  font-size:  14px;
};
${theme.breakpoints.smallView} {
  font-size:  12px;
};
`;
export const ShadeImg = styled.img`
position:absolute;
height: 84px;
${theme.breakpoints.smallView} {
  height: 50px;
};
`
export const CloseImg = styled.img`
position:absolute;
right:8px;
width: 18.517px;
height: 18.517px;
cursor:pointer;
width: 23.517px;
height: 23.517px;
flex-shrink: 0;
@media screen and (min-height: 400px) and (max-height: 700px) {
  width: 18.517px;
  height: 18.517px;

}
`
export const BfsClaim = styled.div`
color: #FFF;
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 17.194px; /* 107.463% */
/* text-decoration-line: underline; */
border-bottom: 1px solid white;
cursor:pointer;
z-index: 9999;
${theme.breakpoints.mediumView} {
  font-size:  14px;
};
${theme.breakpoints.smallView} {
  font-size:  12px;
};
`;
export const SidebarCnt = styled.div`
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  transform: translateX(0);
  transition: all ease-in 0.2s;
  /* @media (max-width: 1220px) {
    position: ${(props) => (props.hum !== "Video" ? "absolute" : "")};
    background-color: ${(props) =>
    props.hum !== "Video" ? "rgba(64, 75, 89, 0.9)" : ""};
    width: ${(props) => (props.hum !== "Video" ? "100%" : "")};
  } */

  /* 
@media (max-width:1780px){
    transform:${(props) =>
    (props.active === "Video" && props.side === false) ||
    (props.active === "Trim" && props.side === false) ||
    (props.active === "Edit" && props.side === false) ||
    (props.active === "Home" && props.side === false)
      ? "translateX(-80%)"
      : "translateX(0)"}
}
@media (max-width:1480px){
    transform:${(props) =>
    (props.active === "Video" && props.side === false) ||
    (props.active === "Trim" && props.side === false) ||
    (props.active === "Edit" && props.side === false) ||
    (props.active === "Home" && props.side === false)
      ? "translateX(-110%)"
      : "translateX(0)"}
} */

  @media (min-width: 1780px) {
    /* z-index:101; */
  }
`;
export const MainCnt = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 92.59%;
  background: ${theme.colors.background};

  .navbar {
    width: 100%;
  }
`;
export const MainCntCap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  background: ${theme.colors.background};
height: 94%;
  .navbar {
    width: 100%;
  }
`;
export const MainCntVid = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  background: ${theme.colors.background};
  ${theme.breakpoints.tabview} {
    height: 92.59%;

  }
  .navbar {
    width: 100%;
  }
`;
export const Indiv = styled.div`
  position: relative;
`;
export const Feeds = styled.div`
  height: 100%;
`;
export const Page = styled.div`
  h1 {
    margin-top: 5%;
    font-weight: 500;
    font-size: 18px;
  }
  p .description {
    padding: 0 10%;
    font-size: 14px;
    font-weight: 400;
  }

  @media (max-width: 1440px) {
    width: 80%;
  }
  @media (max-width: 1200px) {
    width: 90%;
  }
`;
export const Hero = styled.div`
  width: 50%;
  margin: 1% auto;
  @media (max-width: 1440px) {
    width: 60%;
  }

  @media (max-width: 1200px) {
    width: 65%;
  }
`;
export const RecordButton = styled.button`
  background-color: #086972;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 1.5% 7%;
`;
export const Recents = styled.div`
  width: 100%;
  padding-top: 3%;
`;
export const RecentMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-weight: 500;

  img {
    margin-right: 1%;
  }
  .recents {
    color: black;
    font-size: 20px;
    width: 30%;
    text-align: start;
    display: flex;
    align-items: center;
  }
  .library {
    color: #086972;
    font-size: 14px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
`;
export const RecentFeed = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  position: relative;
  justify-content: center;

  @media (max-width: 1200px) {
    column-gap: 1%;
  }
  @media (max-width: 986px) {
    grid-template-columns: 48% 48%;
    column-gap: 2%;
  }

  &.empty {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10% 0;
    p {
      font-size: 2em;
    }
  }
`;
export const RecentCard = styled.div`
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    z-index: 97;
    transform: scale(1.06);
  }
`;
export const Video = styled.div`
  width: 100%;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
export const Duration = styled.div`
  background: #000000;
  border-radius: 2px;
  padding: 0 1%;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.03em;
  color: #ffffff;
  position: absolute;
  bottom: 20px;
  right: 12px;
  z-index: 98;
`;

export const VideoDetails = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
`;
export const Black = styled.div`
  background-color: black;
  display: block;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 97%;
  z-index: 99;
`;
export const Favicon = styled.div`
  padding: 3% 2%;
  width: 15%;
  div {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: pink;
  }
`;
export const Details = styled.div`
  width: 60%;
  text-align: left;
  padding-left: 1%;
  h1 {
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    color: #525252;
  }
`;
export const KebabMenu = styled.div`
  margin-left: auto;
`;
export const Tutorials = styled.div`
  width: 100%;
  margin-top: 8%;
`;
export const CarouselContent = styled.div`
  display: flex;
  background-color: #f8ffff;
`;
export const LeftContent = styled.div`
  width: 55%;
  text-align: start;
  background-color: #f8ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 80%;
  }
  h1 {
    color: #086972;
  }
  p {
    font-size: 14px;
    padding-right: 20%;
  }
`;
export const VideoContent = styled.div`
  width: 45%;

  img {
    width: 100%;
  }
`;
export const WatchButton = styled.button`
  border: #086972 1px solid;
  background-color: #f8ffff;
  color: #086972;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  padding: 1% 6%;
  margin-top: 7%;
`;
export const KebabOption = styled.div`
  text-align: start;
  z-index: 99;
  background-color: white;
  position: absolute;
  right: 1%;
  top: 23%;
  padding: 2% 0 2% 0;
  border-radius: 4px;
  width: 35%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  p {
    font-size: 14px;
    padding: 5% 0 5% 20%;
    margin: 0;
    &:hover {
      background-color: #f2feff;
      color: #086972;
    }
  }
`;

export const PlaylistModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlaylistCnt = styled.div`
  width: 500px;
  height: 305px;
  background-color: #ffffff;
  border-radius: 10px;
  .top {
    padding: 32px 38px 30px 38px;
    border-bottom: 0.5px solid #585763;
  }
  h3 {
    text-align: left;
    font-size: 20px;
    color: #585763;
  }
  .name {
    background-color: #f2f2f2;
    width: 424px;
    height: 52px;
    outline: none;
    border: none;
    border-radius: 6.46053px;
    margin: 17px 0;
    &::placeholder {
      text-align: center;
    }
  }
  .save {
    cursor: pointer;
    background-color: #6c5dd3;
    width: 424px;
    height: 52px;
    font-weight: 600;
    font-size: 16.1513px;
    border-radius: 6.46053px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cancel {
    p {
      cursor: pointer;
    }

    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    color: #585763;
  }
`;
export const UpgradeMessageWrap = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  background: #eadaff;
  z-index: 9999;
  padding: 0px 22px;
`;

export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  line-height: 17px;
  .free-usage {
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    color: #1b1d1b;
  }
  .unlimited {
    margin-left: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #3c453c;
  }
`;
export const UpgradeButton = styled.button`
  display: flex;
  width: 118px;
  height: 26px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3c453c;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3c453c;
  border: 0.75px solid #3c453c;
  background: #eadaff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

export const Removed = styled.div`
  background-color: #dcdcdc;
  position: fixed;
  z-index: 102;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const RemovedNav = styled.div`
  position: fixed;
  top: 0;
  background-color: #2c2c2c;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  .grey {
    margin-left: 44px;
  }
  cursor: pointer;
`;

export const ContentDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 15.3%;
`;

export const InfoContainer = styled.div`
  width: 400px;
  height: 233px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  .requestWrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    margin: 40px 0 16px 0;
  }

  .info {
    color: #404b59;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0.3px;
  }
  .requestInfo {
    width: 287px;
  }
  .description {
    display: flex;
    justify-content: center;
    margin: 16px 0 36px 0;
    color: #667085;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    letter-spacing: 0.5px;
  }
  .requestBtn {
    background: none;
    overflow: hidden;
    display: -webkit-box;
    width: 124px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #4e4e4e;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px; /* 140% */
    display: flex;
    width: 175px;
    height: 40px;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #ccb7fc;
  }
`;

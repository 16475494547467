import styled from "styled-components";
import theme from "../../../theme";
import RenameIconDark from "./Components/LibraryKebab/images/renameIconDark.svg";
import ShareIconDark from "./Components/LibraryKebab/images/shareIconDark.svg";
import RemoveIconDark from "./Components/LibraryKebab/images/removeIconDark.svg";
import RenameIcon from "./Components/LibraryKebab/images/renameIcon.svg";
import ShareIcon from "./Components/LibraryKebab/images/shareIcon.svg";
import RemoveIcon from "./Components/LibraryKebab/images/removeIcon.svg";

import RestoreIcon from "./Components/LibraryKebab/images/restoreIcon.svg";
import RestoreIconDark from "./Components/LibraryKebab/images/restoreIconDark.svg";

export const ButtonContainer = styled.div`
  display: flex;
  user-select: none;
`;

export const FirstButton = styled.button`
  border: 0.5px solid #086972;
  width: 53px;
  height: 31px;
  color: #086972;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-radius: 20px;
  margin-right: 15px;
  background-color: #ffffff;
  transition: 0.3s;

  &:hover {
    background-color: #086972;
    color: #ffffff;
  }

  &.active {
    background-color: #086972;
    color: #ffffff;
  }
`;

export const RemButtons = styled(FirstButton)`
  width: 92px;
`;

export const LibraryHead = styled.div`
  color: ${theme.colors.semiBlack};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  margin-bottom: 2.6%;
  margin-top: 2.3%;
  /* width: 100%; */
`;
export const AllVideosWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AllVideos = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${theme.colors.placeHolder};
  text-align: right;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 185.714% */
  letter-spacing: 0.3px;
`;
export const LibraryHeading = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  /* padding-left:1%; */
  /* font-family: "Inter"; */
  font-style: normal;
  /* font-weight: 600; */
  /* font-size: 18px; */
  /* line-height: 17.19px; */
  /* color:${(props) => props.theme.colors.title1}; */
  /* width:35%; */
  text-align: start;

  color: #1e2126;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */

  .library-icon {
    width: 22px;
    height: 22px;
  }
  &.loader {
    background: linear-gradient(180deg, #edeef2 0%, #e4e6eb 100%);
    height: 38px;
    width: 129px;
    border-radius: 8px;
  }
`;

export const LibraryLogo = styled.div`
  display: flex;
  align-items: center;
`;
export const LibrarySearch = styled.div`
  display: flex;
  width: 445px;
  height: 40px;
  border-radius: 4px;
  input {
    padding: 2% 0 2% 3%;
    width: 90%;
    border: none;
    background-color: #f8f8f9;
    &:focus {
      outline: none;
    }
    ::placeholder {
      font-size: 14px;
      color: #b4b4b4;
    }
  }
  .searchIcon {
    width: 10%;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #f8f8f9;
    cursor: pointer;
  }
`;
export const FilterContainer = styled.div`
  display: flex;
  div {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    &.topic {
      color: #939090;
    }
    &.options {
      color: ${(props) => props.theme.colors.title1};
      cursor: pointer;
      .name {
        position: relative;
        margin-left: 9px;
        margin-right: 7.87px;
      }
    }
  }
`;
export const Types = styled.div`
  position: absolute;
  width: 142px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  left: 0;
  top: 120%;
  border-radius: 4px;
  background-color: #1e1e1e;
  font-size: 14px;
  line-height: 16.94px;
  padding: 15px 0;
  z-index: 2;
  .polygon {
    position: absolute;
    top: -5px;
    right: 58.5px;
    width: 7px;
    height: 7px;
  }

  @media (max-width: 1780px) {
    left: -75%;
    right: 0;
    .polygon {
      right: 8px;
    }
  }
  div {
    width: 100%;
    padding: 6px 15px;
    text-align: start;
    color: white;
    border-radius: 4px;
    &:hover {
      background-color: #6c5dd3;
      color: #ededed;
    }
  }
`;

export const GalleryGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 259px);
  grid-column-gap: 21px;
  grid-row-gap: 30px;
  &.captureSpace {
    margin-bottom: 1.38%;
  }
  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, 259px);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 259px);
  }
  @media (max-width: 842px) {
    grid-template-columns: repeat(2, 259px);
  }
  @media (max-width: 555px) {
    grid-template-columns: repeat(1, 358px);
  }
`;

export const VideoElement = styled.div`
  border-radius: 20px 2px 0px 0px;
  position: relative;
  width: 100%;
  height: 159px;
  video {
    width: 100%;
    height: 100%;
  }
  .bar {
    height: 100%;
    width: 100%;
  }

  .thumbnail {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    object-fit: contain;
  }

  &.loader {
    background-color: #e4e6eb;
  }

  video {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
  }
`;

export const VideoAnime = styled.div`
  position: relative;
  width: 100%;
  height: 159px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #d8bfff 0%,
    #d9c1ff 100%,
    #d8bffe 100%
  );
  .logo {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .ball {
    position: absolute;
    width: 375px;
    height: 375px;
    border-radius: 50%;
    background-color: #6a36f0;
    z-index: 0;
    transform: ${(props) =>
      props.blue ? "translateY(-25%)" : "translateY(50%)"};
    transition: all 0.7s ease-in-out;
    left: -10%;
  }
`;

export const VideoTimeStamp = styled.div`
  padding: 5px;
  position: absolute;
  top: 13px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 100% */
  letter-spacing: 0.5px;
  background-color: ${theme.colors.timecolor};
  border-radius: 5px;
`;

export const VideoDescription = styled.div`
  height: 105px;
  width: 100%;
  height: 154px;
  display: flex;
  padding: 27px 28px 24px 20px;

  &.loader {
    display: block;
    padding-top: 9px;
    padding-left: 15px;
  }
`;

export const VideoIcon = styled.div`
  background: #ffa2a2;
  border-radius: 50%;
  padding: 1.5% 0;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .website-icon {
    width: 75%;
  }
`;

export const VideoName = styled.div`
  color: ${(props) => props.theme.colors.title1};
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .name {
    color: ${theme.colors.lightText1};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    letter-spacing: 0.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 52px;

  }
  input {
    &.name {
      width: 100%;
      padding: 1%;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;
      color: ${(props) => props.theme.colors.title1};
      &::selection {
        background-color: #6a36f0;
        color: white;
      }
      &:focus {
        outline: #e0e0e0;
        border-radius: 4px;
      }
      &::placeholder {
        color: ${(props) => props.theme.colors.title1};
      }
    }
  }

`;

export const VideoDate = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: start;
  letter-spacing: -3%;
  color: #939090;
  display: flex;
  justify-content: space-between;
  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
  .date-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
`;

export const VideoAnimation = styled.div`
  cursor: pointer;
  /* box-shadow: 0px 16px 40px 0px #A5A5A533; */
  background-color: white;
  position: relative;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  .options {
    opacity: 0;
    width: 26px;
    height: 20px;
    flex-shrink: 0;
    background-color: ${theme.colors.timecolor};
    position: absolute;
    top: 43px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .trashOption {
    opacity: 0;
    width: 26px;
    height: 20px;
    flex-shrink: 0;
    background-color: ${theme.colors.timecolor};
    position: absolute;
    top: 43px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  &:hover {
    /* box-shadow: 0px 16px 40px 0px #A5A5A533; */
    outline: 1px solid ${theme.colors.hoverBorder};

    .options,
    .trashOption {
      opacity: 1;
    }
  }
  &.loader {
    box-shadow: none;
    border: none;
    outline: none;
  }

  .three-dots {
    width: 8%;
    height: 100%;
    height: 14px;
    width: 14px;
    margin-right: 3%;
    margin-top: 1%;
    /* rotate: 90deg; */
    position: relative;
    z-index: 999;
  }
`;

export const HomeContainer = styled.div`
  * {
    font-family: "Inter", sans-serif;
  }
  background-color: #fefefe;
  position: relative;
`;

export const Content = styled.div`
  background-color: #fefefe;
`;
export const Indiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.colors.background};
  overflow-y: auto;
`;
export const Feeds = styled.div`
  padding: 24px 26px 24px 24px;
`;
export const Page = styled.div`
  /* width: 100%; */
  height: 100%;
  display: flex;

  &.emptycase {
    /* justify-content: center; */
    margin-top: 5.01%;
    height: 90%;
  }
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const KebabOption = styled.div`
  text-align: start;
  position: absolute;
  top: 28px;
  right: 2px;

  z-index: 99;
  width: 195px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(64, 75, 89, 0.2);
  color: ${theme.colors.lightText1};
  padding-top: 24px;
  &.captureHeight {
    height: 121px !important;
  }
  .polygon {
    position: absolute;
    top: -5px;
    right: 23px;
    width: 7px;
    height: 7px;
  }
`;

export const KebabOptionTrash = styled.div`
  text-align: start;
  position: absolute;
  top: 73px;
  right: 9px;

  z-index: 99;
  width: 195px;
  height: 128px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(64, 75, 89, 0.2);
  color: ${theme.colors.lightText1};
  padding-top: 24px;

  .polygon {
    position: absolute;
    top: -5px;
    right: 23px;
    width: 7px;
    height: 7px;
  }
`;
export const Empty = styled.div`
  display: flex;
  /* width:100%; */
  justify-content: center;
  align-items: center;
  margin: 15% 0;
  /* background-color: red; */
  .empty {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* position:absolute; */

    h1 {
      /* font-size:24px;
      margin-bottom:4%; */

      color: #404b59;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    h6 {
      /* color:#3C453C;
      font-size:18px;
      margin-bottom:2.5%; */

      width: 410px;
      color: #404b59;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      letter-spacing: 0.5px;
    }
    p {
      color: #667085;
      text-align: right;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin: 0px;
    }
  }
  .sharedEmptyImg {
    margin-bottom: 40px;
  }
  .goLibraryWrapper {
    display: flex;
    margin-top: 69px;
    cursor: pointer;
  }
`;
export const Shade = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 17px 18px -10px rgba(0, 0, 0, 0.4),
    inset 0px -11px 8px -10px #ccc;
`;

export const TrashMsgWrap = styled.div`
  display: flex;
  /* background: #F8F8F9; */
  border-radius: 4px;
  /* height:40px;
width: 975px; */
  margin-bottom: 26px;
  align-items: center;
  justify-content: flex-start;
`;

export const TrashMsg = styled.div`
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  ${theme.breakpoints.tab} {
    font-size: 12px;
  }
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 92.857% */
  letter-spacing: 0.5px;
`;

export const LibraryLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const LibraryName = styled.div`
  height: 18px;
  width: 95%;
  background: linear-gradient(90.05deg, #edeef2 0.76%, #e4e6eb 101.03%);
  border-radius: 8px;
  margin-top: 4px;

  &.line-2 {
    width: 154px;
  }

  &.line-3 {
    margin-top: 13px;
    width: 85px;
  }
`;

export const SharedUsrname = styled.div`
  color: ${theme.colors.placeHolder};
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 100% */
  letter-spacing: 0.5px;
  margin-bottom: 16px;
`;

export const CountSection = styled.div`
  display: flex;
  flex-direction: row;
  color: ${theme.colors.placeHolder};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.5px;
`;

export const CountDiv = styled.div`
  margin-right: 5px;
`;
export const ViewDiv = styled.div`
  margin-left: 5px;
`;
export const KebabRenameImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 36px;
  background-image: url(${RenameIconDark}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  margin-right: 5px;
`;
export const KebabRestoreImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 36px;
  background-image: url(${RestoreIconDark}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  margin-right: 5px;
`;
export const KebabShareImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 36px;
  background-image: url(${ShareIconDark}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  margin-right: 5px;
`;
export const KebabRemoveImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 36px;
  background-image: url(${RemoveIconDark}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  margin-right: 5px;
`;

export const KebabDiv = styled.div`
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  display: flex;

  display: flex;
  align-items: center;
  padding-left: 20px;

  p {
    color: #404b59;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.194px; /* 122.814% */
    letter-spacing: 0.3px;
  }

  &:hover {
    background-color: ${theme.colors.lightGrey};

    img {
      background-color: ${theme.colors.lightPurple};
    }
    ${KebabRenameImg} {
      background-image: url(${RenameIcon});
    }
    ${KebabShareImg} {
      background-image: url(${ShareIcon});
    }
    ${KebabRemoveImg} {
      background-image: url(${RemoveIcon});
    }
  }

  p {
    text-align: start;
    line-height: 16.94px;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
  }
`;
export const TrashKebabDiv = styled.div`
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  display: flex;

  display: flex;
  align-items: center;
  padding-left: 20px;
&.dis{
  pointer-events: none;
}
  p {
    color: #404b59;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.194px; /* 122.814% */
    letter-spacing: 0.3px;
  }

  &:hover {
    background-color: ${theme.colors.lightGrey};

    img {
      background-color: ${theme.colors.lightPurple};
    }
    ${KebabRestoreImg} {
      background-image: url(${RestoreIcon});
    }

    ${KebabRemoveImg} {
      background-image: url(${RemoveIcon});
    }
  }

  p {
    text-align: start;
    line-height: 16.94px;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
  }
`;
export const LibraryContWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #D9D9D9; 
            border-radius: 6px;
        }

        /* &::-webkit-scrollbar-track:hover {
            background-color: #555; 
        } */

        &::-webkit-scrollbar-thumb:hover {
            background-color: #D9D9D9; 
        }
`;
export const RectangleLayer = styled.div`
  height: 100%;
  width: 100%;
  background: #1e2126;
  cursor: inherit;
  position: absolute;
  /* top:; */
  z-index: 9;
  opacity: 0.3;
  border-radius: 20px 20px 0px 0px;
`;

export const VideoThumbnailCont = styled.div`
  /* display:block; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  background-color: ${theme.colors.defaultThumbColor};
  display: flex;
  justify-content: center;
  align-items: center;
  .defaultThumb {
    width: 24px;
    height: 24px;
  }
`;
export const DashBoardWrapper = styled.div``;
export const RetryBut = styled.div`
width: 126px;
height: 28px;
border-radius: 20px;
background: #FFF;
position: absolute;
z-index: 999999;
top: 40%;
left: 25%;
padding: 7px 16px;
display: none;
justify-content: center;
align-items: center;
gap: 8px;
${VideoAnimation}:hover & {
    display: flex;
  }
`;
export const Film = styled.img`
width: 14px;
height: 14px;
`
export const RetryText = styled.div`
color: #282F3C;
font-family: "Inter";
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 10px; /* 100% */
letter-spacing: 0.5px;
`
import React from "react";
import { FontFamily, FontSecWrap, FontSize } from "../../../brandkit-styled";
import ArrowDown from "../../../Images/arrowDown.svg";
import FontFamilyMenu from "./fontFamilyMenu";
import { useBrandKit } from "../../../context/brandContext";

const FontSec = (props) => {
  const { brandkitState, brandkitDispatch } = useBrandKit();
  const { fontFamily, brandKit } = brandkitState;
  const { type } = props;
  return (
    <FontSecWrap>
      <FontFamily
        onClick={() => {
          if (type === "head") {
            brandkitDispatch({
              type: "HANDLE_FONT_FAMILY_MENU",
              data: !fontFamily?.open,
            });
          }
        }}
       
      >
        <div> {brandKit?.font?.fontFamily} </div>
        <img src={ArrowDown} alt="no-icon" draggable={false} />
        {(  fontFamily?.open) && <FontFamilyMenu type={type} />}
      </FontFamily>
    </FontSecWrap>
  );
};

export default FontSec;

import styled from "styled-components";
import theme from "../../../theme";
import Duplicate from "./Images/duplicate.svg";
import DuplicateHover from "./Images/duplicateHover.svg";
import Trash from "./Images/trash.svg";
import editNormal from "./Images/editNormal.svg"
import editHover from "./Images/editHover.svg"
import TrashHover from "./Images/trashHover.svg";
import DefaultWall from "./Images/defaultWall.svg";
import EditText from "./Images/editText.svg";
import EditTextHover from "./Images/editTextHover.svg";
import EditDesign from "./Images/designEdit.svg";
import EditDesignHover from "./Images/designEditHover.svg";
import EditConfig from "./Images/configEdit.svg";
import EditConfigHover from "./Images/configEditHover.svg";
import EditLayout from "./Images/layoutEdit.svg";
import EditLayoutHover from "./Images/layoutEditHover.svg";
import Theme from "./Images/theme.svg"
export const CaptureDemoEditWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 24px 24px;
  gap: 24px;

`;
export const FlowWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 318px;  //16.56%
  border-radius: 10px;
  background: #EAEAEA;
  position: relative;
  height: 100%;
  ${theme.breakpoints.largeLapView} {
  width:280px;
  }
  ${theme.breakpoints.lapView} {
  width:250px;
  }
  ${theme.breakpoints.tabViewCapt} {
  width:200px;
  }
`;
export const FlowHead = styled.div`
  color: #404B59;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-top: 14px;
`;
export const FlowDiv = styled.div`
background: #D0D0D0;
width: 100%;
height: 0.76px;
margin-top: 14px;
`

export const FlowItemsWrap = styled.div`
padding: 0px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 304px;
  ${theme.breakpoints.largeLapView} {
  width:274px;
  }
  ${theme.breakpoints.lapView} {
  width:244px;
  }
  ${theme.breakpoints.tabViewCapt} {
  width:194px;
  }
  height: 100%;
  overflow: hidden;
  gap: 16px;
  margin-top: 16px;
  overflow-x: hidden;
  &:hover{
  overflow-y: auto;
  }
  &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
background: #CDCDCD;
margin-right: 8px;
        }
input[type="file"] {
    display: none;
    pointer-events: none;
  }
`;
export const StepKebab = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
display: none;
 
`;
export const FlowImgCont = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 150px; */
  width: 100%;
  gap: 7px;
position: relative;
&:hover{
    ${StepKebab}{
      display: block;
    }
  }
`;
export const BackCont = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1e2126;
  opacity: 0.3;
  border-radius: 8px;
`;
export const Flowimg = styled.img`
  width: 100%;
  height: 100%;
`;
export const AddStepWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #5429ff;
  cursor: pointer;
  color: #5429ff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 25px;
  width: 149px;
`;
export const StepNo = styled.div`
  color: #A0A0A0;
text-align: right;
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
min-width: 21px;
${theme.breakpoints.largeLapView} {
  width:18px;
  }
  ${theme.breakpoints.lapView} {
  width:15px;
  }
  ${theme.breakpoints.tabViewCapt} {
  width:10px;
  }
`;

export const StepKebabSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 175px;
  height: fit-content;
  border-radius: 20px;
  background: #fff;
  /* shadow */
  box-shadow: 0px 5px 10px 0px rgba(64, 75, 89, 0.2);
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 20px 0px;
  z-index: 9999;
`;
export const KebabEditImg = styled.div`
 width: 32px;
  height: 32px;
  border-radius: 36px;
  background-image: url(${editNormal}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  margin-right: 5px;
`
export const KebabDuplicateImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 36px;
  background-image: url(${Duplicate}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  margin-right: 5px;
`;
export const KebabTrashImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 36px;
  background-image: url(${Trash}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  margin-right: 5px;
`;
export const KebabDiv = styled.div`
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 20px;
  opacity: ${(props) => props?.disable ? '0.5' : '1'};
  cursor: pointer;
  p {
    color: #404b59;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.194px; /* 122.814% */
    letter-spacing: 0.3px;
  }

  &:hover {
    background-color: ${theme.colors.lightGrey};

    img {
      background-color: ${theme.colors.lightPurple};
    }
    
    ${KebabDuplicateImg} {
      background-image: url(${DuplicateHover});
    }
    ${KebabTrashImg} {
      background-image: url(${TrashHover});
    }
    ${KebabEditImg} {
      background-image: url(${editHover});
    }
  }

  p {
    text-align: start;
    line-height: 16.94px;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
  }
`;

export const MainStepSectionWrap = styled.div`
  width:100%;
  height: 94%;
  overflow:hidden;
  border-radius: 0px 0px 10px 10px;
  background-color: ${(props) => props?.default === 'default' ? '#fff' : props?.default};
  padding: 20px;
  position: relative;
  background-image: ${(props) => props?.default === 'default' && `url(${DefaultWall})`}; // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
`;
export const DemoEditBodyWrap = styled.div`
width: 80.625%;
  height: 100%;
 
  border-radius: 10px;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  
`
export const InterActiveDemoWrap = styled.div`
border-radius: 10px 10px 0px 0px;
background: #FFF;
color: #404B59;
font-family: 'Inter';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
letter-spacing: 0.32px;
width: 100%;
height: 54px;
display: flex;
align-items: center;
justify-content: space-between;
img{
  cursor: pointer;
}
form{
  width: 30%;
}
padding:0px 24px ;
`
export const TitleWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
`
export const DocBgWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
color: #404B59;
font-family: 'Inter';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
cursor: pointer;
`
export const DocTitleInput = styled.input`
width: 100%;
height: 30px;
color: rgba(64, 75, 89, 0.80);
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  outline: none;
  border-radius: 5px;
  padding: 0px 10px;
 
`
export const ThemeCont = styled.label`
  display: flex;
  height: 20px;
  width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
background-image:${(props) => props?.bg === 'default' && `url(${Theme})`} ;
background-color:${(props) => props?.bg === 'default' ? `#ffffff` : props?.bg};
background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  cursor: pointer;
  .sketch-picker {
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 99999999;
    width: 240px!important;

  height: 405px !important;
  .flexbox-fix > *:nth-child(n+10) {
  display: none; /* Hide the 10th and subsequent children */
}
  }
`;
export const DeafultWrap = styled.div`
 display: flex;
 flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 20px;
  gap:2px;
  position: absolute;
  bottom: 15px;
    position: absolute;
          top:365px;
          left: -20%;
          transform:translateX(-80%);
          z-index: 99999999;
          color: #667085;
font-family: 'Inter';
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 150%;
text-align: center;
img{
  width: 18px;
  height: 18px;
}
`
export const ColorSaveBtn = styled.button`
      
          border: 'none';
          cursor: pointer;
          border-radius: 4px;
          background-color: #6528F7;
          border: 1px solid rgba(0, 0, 0, 0);
          color: #FFF;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.07px;
          z-index:99999999;
          height: 33px;
      padding: 0px 20px;



`
export const StepTitleWrap = styled.div`
height: 7.79%;
width: 100%;
background-color: #FFFFFF;
padding: 24px;
font-family: 'Inter';
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;
display: flex;
align-items: center;
justify-content: flex-start;
gap: 5px;

`
export const StepTitle = styled.div`
outline: none;
max-width:90%;
min-width: 40%;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
`
export const StepContainerWrap = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height:87%;
background-color: #FFFFFF;
padding: 24px;
flex-direction: column;
;
`
export const StepContainer = styled.div`
  width:100%;
  height:${(props) => props?.start ? '100%' : '89.29%'} ;
  position: relative;

`;
export const StepImg = styled.img`
  width: 100%;
  height: 100%;
`;
export const FeatureSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 18.736%;
  height: 100%;
  border-radius: 5px;
  background: #fff;
  padding: 0px 12px;
  position: relative;
`;

export const CalloutConfigWrap = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 17px;
  color: #404b59;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  cursor: pointer;
  .up-arrow{
    transform: rotate(180deg);
  }
`;
export const CalloutConfigHeader = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
export const CalloutSectionWrap = styled.div`
  width: 100%;
  height: 70%;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #e6e6e6;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 10px 0px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 6px;
  }
`;
export const FeatureSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 0 30.5px;
  align-items: flex-start;
  margin-top: 12px;
`;
export const ItemHeading = styled.div`
  color: #404b59;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;
export const ColorContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 12px;

`
export const ColorSec = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
export const ColorText = styled.div`
  color: #667085;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;
export const ColorCont = styled.div`
  display: flex;
  height: 30px;
  width: 94px;
  padding: 4px 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 7px;
  border: 1px solid #edeff2;
  background: #f7f8fa;
  cursor: pointer;
`;
export const ColorInput = styled.input`
border: none;
    width: 0;
    height: 0;
    opacity: 0;

`
export const BgColorDiv = styled.div`
min-width: 20px;
height: 20px;
border-radius: 3px;
background: ${(props) => props?.color && props?.color};
`
export const BgColorSelected = styled.div`
color: #667085;
font-family: "Inter";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
`
export const ColorDiv = styled.div`
min-width: 20px;
height: 20px;
border-radius: 3px;
background: ${(props) => props?.color && props?.color};
`
export const ColorSelected = styled.div`
color: #667085;
font-family: "Inter";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
`
export const Dividor = styled.div`
  width: 100%;
  height: 0.5px;
  background: rgba(64, 75, 89, 0.1);
  margin-top: 12px;
`;
export const HeadingContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 12px;
`
export const HeadingInput = styled.input`
  display: flex;
  width: 100%;
  height: 42px;
  padding: 0px 17px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #dce1e9;
  outline: none;
  background: #fff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &::placeholder {
    color: rgba(102, 112, 133, 0.5);
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
`;
export const TextWrap = styled.div`
  width: 100%;
  height: 106px;
  border-radius: 5px;
  border: 0.5px solid rgba(102, 112, 133, 0.4);
  background: #fff;
`;
export const TextFeaturesWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 28.5px;
  border-bottom: 0.5px solid rgba(102, 112, 133, 0.4);
`;


export const TextInput = styled.div`
  width: 100%;
  border: none;
  height: 71%;
  resize: none;
  outline: none;
  padding: 10px;
  font-family: "Inter";
  font-size: 12px;
  font-style: ${(props) => props?.italics ? 'italic' : 'normal'} ;
  font-weight:${(props) => props?.bold ? '600' : '400'} ;
  text-decoration:${(props) => props?.underline ? 'underline' : 'normal'} ;
  line-height: 150%;
  &::placeholder {
    color: rgba(102, 112, 133, 0.5);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
`;
export const FeatureDropDown = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 17px;
  color: #404b59;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  .up-arrow {
    transform: rotate(180deg);
  }
`;
export const CalloutStyleRow = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
`;

export const SaveCancelWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap:2px;
  position: absolute;
  bottom: 15px;
    position: absolute;
          top: ${(props) => props?.design ? '375px' : '415px'};
          left: ${(props) => props?.design ? '70%' : '26%'};
          transform:${(props) => props?.design ? 'translateX(42%)' : 'translateX(-60%)'};
          z-index: 99999999;
`;
export const SaveBtn = styled.button`
  display: flex;
height: 33px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
  background-color: #6528F7;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #FFF;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.07px;
`;
export const CancelBtn = styled.div`
  display: flex;
  height:33px;
  padding: 0px 20px;

  justify-content: center;
  align-items: center;
  gap: 94px;
  border-radius: 5px;
  border: none;
  color: #5429ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  background-color: none;
  cursor: pointer;

`;
export const StepDescriptionContainer = styled.div`
display: flex;
align-items: center;
gap: 20px;
position: absolute;
/* width: 324px;
  max-height: 153px; */
  min-width: ${(props) => props?.editStatus ? "324px" : "auto"};
  min-height: ${(props) => props?.editStatus ? "153px" : "auto"};
  height: fit-content;
  width: fit-content;
/* transform: scale(1.3); */
visibility: visible;
flex-direction: ${(props) => props?.arrowAlignment === "Left" ? "row-reverse" : "row"};
`
export const StepDescriptionCont = styled.div`
display: flex;
 align-items:flex-start ;

gap: 20px;
/* width: 324px;
  max-height: 153px; */
  min-width: ${(props) => props?.editStatus ? "324px" : "auto"};
  min-height: ${(props) => props?.editStatus ? "153px" : "auto"};
  height: fit-content;
visibility: visible;
position: absolute;
flex-direction: ${(props) => props?.arrowAlignment === "Left" ? "row-reverse" : "row"};
`
export const StepDescAlignDiv = styled.div`
display: flex;
    gap: 20px;
    position: relative;
    align-items:${(props) => (props?.arrowAlignment === 'Left' || props?.arrowAlignment === 'Right') ? 'center' : 'flex-start'} ;
    
   #stepArrow{
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid ${(props) => props?.bg && props?.bg} ;
}
`
export const ToolTipAlignCont = styled.div`

  border-radius: 8px;
  background-color:${(props) => props?.bg && props?.bg};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  /* position: absolute;
  top: 20%;
  right: 20%; */
  font-family:${(props) => props?.font && props?.font};
  outline: ${(props) => props?.borderColor !== 'No Fill' && `2px solid ${props?.borderColor}`};
`
export const StepDescriptionWrap = styled.div`
position: relative;
width: max-content;
height: max-content;
  border-radius: 8px;
  background-color:${(props) => props?.bg && props?.bg};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* position: absolute;
  top: 20%;
  right: 20%; */
  font-family:${(props) => props?.font && props?.font};
  outline: ${(props) => props?.borderColor !== 'No Fill' && `2px solid ${props?.borderColor}`};
  #stepArrow{
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid ${(props) => props?.bg && props?.bg} ;
}
`;
export const StepDescription = styled.div`
  padding:${(props) => props?.media ? props?.btn ? '14px 16px 0px 14px' : '14px 16px 14px 14px' : '14px 16px'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  height: fit-content;
  border-radius: 3.69px;
  text-align: left;
  cursor:${(props) => props.edit && 'pointer'} ;
  opacity: 1;

  .descStyling{
    outline: none;
  /* resize: none; */
  min-width: ${(props) => props?.editStatus ? "274px" : "auto"};
    max-width: 464px;
    min-height: ${(props) => props?.editStatus ? "67px" : "auto"};
    max-height: 300px;
    height: auto;
    width: auto;
  border: none;
  color: ${(props) => props?.color && props?.color};
  font-size: 14px;
  font-style: ${(props) => props?.italics ? 'italic' : 'normal'} ;
  text-decoration:${(props) => props?.underline ? 'underline' : 'normal'} ;
  font-weight:${(props) => props?.bold ? '600' : '400'} ;
  line-height: 150%; /* 21px */
  &.disable{
    color:${(props) => props?.color ? props?.color : '#fff'} ;
    border-radius: 2px;
    border: 0.8px dashed #DDD;
    margin-top: 4px;
    height: 100%;
    padding: 4px;
  }
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    
  }
  .headingStyling{
    outline: none;
  /* resize: none; */
  min-width: ${(props) => props?.editStatus ? "274px" : "auto"};
    max-width: 400px;
    min-height: ${(props) => props?.editStatus ? "25px" : "auto"};
    max-height: 300px;
    height: auto;
    width: auto;
  border: none;
  color: ${(props) => props?.color && props?.color};
  font-size: 14px;
  font-style: ${(props) => props?.italics ? 'italic' : 'normal'} ;
  text-decoration:${(props) => props?.underline ? 'underline' : 'normal'} ;
  font-weight:${(props) => props?.bold ? '600' : '400'} ;
  line-height: 150%; /* 21px */
  &.disable{
    color:${(props) => props?.color ? props?.color : '#fff'} ;
    border-radius: 2px;
    border: 0.8px dashed #DDD;
    margin-top: 4px;
    height: 100%;
    padding: 4px;
  }
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    
  }
`;
export const StepHeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${(props) => props?.color && props?.color};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */

  .disable{
    color: #fff;
    border-radius: 2px;
    border: 0.8px dashed #DDD;
    padding: 0px 4px ;

  }
  div{
     overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
  }
`;
export const DescriptionSec = styled.div`
  outline: none;
  /* resize: none; */
  min-width: ${(props) => props?.editStatus ? "274px" : "auto"};
    max-width: 500px;
    min-height: ${(props) => props?.editStatus ? "67px" : "auto"};
    max-height: 300px;
    height: auto;
    width: auto;
  border: none;
  color: ${(props) => props?.color && props?.color};
  font-size: 14px;
  font-style: ${(props) => props?.italics ? 'italic' : 'normal'} ;
  text-decoration:${(props) => props?.underline ? 'underline' : 'normal'} ;
  font-weight:${(props) => props?.bold ? '600' : '400'} ;
  line-height: 150%; /* 21px */
  &.disable{
    color:${(props) => props?.color ? props?.color : '#fff'} ;
    border-radius: 2px;
    border: 0.8px dashed #DDD;
    margin-top: 4px;
    height: 100%;
    padding: 4px;
  }
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;
export const StepNoNextWrap = styled.div`
  display: flex;
  justify-content: ${(props) => props?.status ? 'space-between' : 'flex-end'};
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.70);
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: -0.24px;

  padding: 0px 16px 15px 16px;
    cursor:${(props) => props.edit && 'pointer'} ;
`;

export const EditOptionsWrap = styled.div`
width: 30px;
display: flex;
flex-direction: column;
gap: 8px;
position: relative;
order:${(props) => props?.status ? "1" : "unset"};
/* transform:scale(1.3); */
height: fit-content;
`
export const TooltipText = styled.div`
height: 20px;
padding: 0px 12px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 4px;
background: #667085;
position: absolute;
bottom:-20px;
color: #FFF;
font-family: 'Inter';
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 18px */
z-index: 9999;
display: none;
`
export const EditItem = styled.div`
width: 16px;
height: 16px;
background-repeat: no-repeat;
background-size: cover; // Optional: You can adjust this to control how the image covers the container
background-image: ${(props) => props?.type === 'text' ?
    props?.feature === 'text' ? `url(${EditTextHover})` :
      `url(${EditText})`
    : props?.type === 'design' ?
      props?.feature ? `url(${EditDesignHover})` :
        `url(${EditDesign})`
      : props?.type === 'config' ?
        props?.feature ? `url(${EditConfigHover})` :
          `url(${EditConfig})`
        : props?.type === 'layout' ?
          props?.feature ? `url(${EditLayoutHover})` :
            `url(${EditLayout})` : ''};

`
export const EditItemWrap = styled.div`
position: relative;
display: flex;
width: 30px;
height: 30px;
justify-content: center;
align-items: center;
border-radius: 8px;
background:${(props) => props?.feature ? '#6A36F0' : ' #FFF'};
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
cursor: pointer;
&.disable{
  pointer-events: none;
  opacity: 0.8;
}
&:hover{
  background: #6A36F0;
  ${TooltipText}{
      display:flex // Replace with your image path
    }
&.text{
    ${EditItem}{
      background-image: url(${EditTextHover}); // Replace with your image path
    }
  }
  &.design{
    ${EditItem}{
      background-image: url(${EditDesignHover}); // Replace with your image path
    }

  }
  &.config{
    ${EditItem}{
      background-image: url(${EditConfigHover}); // Replace with your image path
    }
  }
  &.layout{
    ${EditItem}{
      background-image: url(${EditLayoutHover}); // Replace with your image path
    }

  }
}
`;

export const NextStepWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  img{
    cursor: pointer;
  }
`;
export const TextBtn = styled.button`
display: inline-flex;
height: 20px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 4px;
border: none;
background:${(props) => props?.color && props?.color};
color: #fff;
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 12px */
letter-spacing: -0.24px;
cursor: pointer;
`
export const NextArrowWrap = styled.div`
display: flex;
width: 20px;
height: 20px;
padding: 5px 3px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background-color: ${(props) => props?.bg ? props?.bg : 'rgba(255, 255, 255, 0.50)'};
&.prev{
  transform: rotate(180deg);
}
cursor: pointer;
`
export const AllignmentFontWrap = styled.div`
width:180px;
height:${(props) => props?.font ? '218px' : '178px'};
border-radius: 5px;
background: #FFF;
box-shadow: 0px 5px 10px 0px rgba(64, 75, 89, 0.20);
padding: 10px 12px;
text-align:left ;
position: absolute;
left: 0;
top:30px;
z-index: 999999;
`
export const AllignmentWrap = styled.div`
width:258px;
height:${(props) => props?.font ? '218px' : '178px'};
border-radius: 5px;
background: #FFF;
box-shadow: 0px 5px 10px 0px rgba(64, 75, 89, 0.20);
padding: 10px 12px;
text-align:left ;
position: absolute;
left: 0;
top:42px;
z-index: 999999;
`
export const AllignmentFontItem = styled.div`
color: #404B59;
font-feature-settings: 'liga' off, 'clig' off;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17.194px; /* 122.814% */
letter-spacing: 0.3px;
height: 40px;
width: 100%;
justify-content: flex-start;
gap:4px;
display: flex;
align-items: center;
padding: 0px 12px;
cursor: pointer;
&:hover{
  border-radius: 4px;
background: #F4F6F8;
}
`




export const TotalStepFrameDiv = styled.div`
border-radius: 4px;
/* height: 630px; */
/* margin-top:20px; */
width: 100%;
/* height: 100%; */
background-size: cover;
transform: scale(${props => (props?.status && props?.status)});
transform-origin: left top;
/* margin: 0px 10px; */
position: relative;
display: none;
.image-container {
    width: fit-content;
    height: fit-content;
    display: none;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image {
    /* position: absolute;
    width: 100%;
    height: 100%; */
    opacity: 0;
    animation: fade 0.8s infinite;
}

#image1 {
    animation-delay: 0s;
}

#image2 {
    animation-delay: 1s;
}

#image3 {
    animation-delay: 2s;
}

@keyframes fade {
    /* 0% { opacity: 0; } */
    33.33% { opacity: 1; }
    /* 66.66% { opacity: 0; }
    100% { opacity: 0; } */
}
`
export const EditPageAlignDiv = styled.div`
width: 100%;
height: 100%;
display: flex;
gap: 24px;
${theme.breakpoints.largeLapView} {
  gap:20px;
  }
  ${theme.breakpoints.lapView} {
  gap:16px;
  }
  ${theme.breakpoints.tabViewCapt} {
  gap:11px;
  }
justify-content: flex-start;
`
export const DemoStepEditHighlight = styled.div`
display:none;
width: 46px;
height: 46px;
border-radius: 50%;
background-color: rgba(240, 100, 140, 0.3);
border: 2px solid rgb(240, 100, 140);
position: absolute;
transition: top 0.5s ease, left 0.5s ease;
`

export const FlowSectionStepDiv = styled.div`
display: flex;
justify-content: center;
align-items:center ;
border-radius: 8px;
height:${(props) => !props?.thumb && '150px'} ;
width: 260px;
${theme.breakpoints.largeLapView} {
  width:230px;
  }
  ${theme.breakpoints.lapView} {
  width:200px;
  }
  ${theme.breakpoints.tabViewCapt} {
  width:150px;
  }
/* height: 100%; */
background-size: cover;
transform: scale(${props => (props?.status && props?.status)});
transform-origin: left top;
/* margin: 0px 10px; */
position: relative;
padding: 7px 5px;
  background: #AEAEAE;
  border: ${(props) => props?.active ? '1px solid #F0648C' : 'none'} ;
  /* &:hover{
    border: 1px solid #F0648C ;
  } */
cursor: pointer;
`
export const StepThumb = styled.img`
width:100%;
height:100%;
object-fit: contain;
`
export const HideImage = styled.img`
width: 50px;
height: 50px;
`
export const TotalPositionDiv = styled.div`
width:500px;
height: 500px;
/* width: fit-content;
height: fit-content; */
position: absolute;
display: none;

`
export const MediaModalWrap = styled.div`
width: 100%;
height: 67px;
padding: 4px;
border: 0.8px dashed #DDD;
border-radius: 2px;
`
export const MediaSection = styled.div`
width: 100%;
height: 100%;
border-radius: 4px;
background: #F5F5F5;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 3px;
color: rgba(28, 28, 28, 0.80);
font-family: 'Inter';
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 15px */
input[type="file"] {
    display: none;
    pointer-events: none;
  }
`
export const MediaDescriptionSec = styled.div`
  outline: none;
  resize: none;
  max-height: 24px;
  width: 100%;
  border: none;
  color: ${(props) => props?.color && props?.color};
  font-size: 14px;
  font-style: ${(props) => props?.italics ? 'italic' : 'normal'} ;
  text-decoration:${(props) => props?.underline ? 'underline' : 'normal'} ;
  font-weight:${(props) => props?.bold ? '600' : '400'} ;
  line-height: 150%; /* 21px */
  &.disable{
    color: #fff;
    border-radius: 2px;
    border: 0.8px dashed #DDD;
    margin-top: 4px;
    height: 67px;
    padding: 0 4px;
  }
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;
export const MediaNextBtnWrap = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
height: 40px;
`
export const MediaNextBtn = styled.div`
display: flex;
height: 20px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
border: none;
background:${(props) => props?.color && props?.color} ;
color: #fff;
font-family: 'Inter';
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 12px */
letter-spacing: -0.24px;
`

export const IntroVideoButtonCont = styled.div`
height: 32px;
border: 0.5px solid #404B59;
display: flex;
justify-content: center;
align-items: center;
border-radius: 4px;
gap:10px;
cursor: pointer;
width: 260px;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: center;
color: #404B59;

${theme.breakpoints.largeLapView} {
  width:230px;
  }
  ${theme.breakpoints.lapView} {
  width:200px;
  }
  ${theme.breakpoints.tabViewCapt} {
  width:150px;
  }
&:hover{
  color:#5429FF;
  border: 0.5px solid #5429FF;
  path{
    fill:#5429FF
  }
}

`
export const CaptureIntroVideoCont = styled.div`
    height: 95%;
    width: 100%;
    background: black;
  video{
    width: 100%;
    height: 100%;
  }
`
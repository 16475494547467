import React from "react";
import {
  FontFamilyMenuItem,
  FontFamilyMenuWrap,
} from "../../../brandkit-styled";
import { useBrandKit } from "../../../context/brandContext";

const FontFamilyMenu = (props) => {
    const { brandkitState, brandkitDispatch } = useBrandKit();
  const { type } = props;
  const handleFont = (val) => {
    if(type==='head'){
    brandkitDispatch({
        type: "HANDLE_FONT_FAMILY",
        data: val,
      });
    }
    
  };
  return (
    <FontFamilyMenuWrap font={true}>
      <FontFamilyMenuItem
        onClick={() => {
          handleFont("Inter");
        }}
      >
        Inter
      </FontFamilyMenuItem>
      <FontFamilyMenuItem
        onClick={() => {
          handleFont("Roboto");
        }}
      >
        Roboto
      </FontFamilyMenuItem>
      <FontFamilyMenuItem
        onClick={() => {
          handleFont("Montserrat");
        }}
      >
        Montserrat
      </FontFamilyMenuItem>
      <FontFamilyMenuItem
        onClick={() => {
          handleFont("Poppins");
        }}
      >
        Poppins
      </FontFamilyMenuItem>
      <FontFamilyMenuItem
        onClick={() => {
          handleFont("Lato");
        }}
      >
        Lato
      </FontFamilyMenuItem>
    </FontFamilyMenuWrap>
  );
};

export default FontFamilyMenu;

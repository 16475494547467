import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { VideoUploadWrap, ItemRHS, ItemWrap, UploadVideoWrap, UploadVideoSec, SizeText, IntroOutroKebab, SizeWrap } from "../../brandkit-styled";
import KitItemHead from "../kitItemHead";
import AddVideo from '../../Images/addVideo.svg'
import { useBrandKit } from "../../context/brandContext";
import { captureClient } from "../../../../../utils/axios-utils";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import getBlobDuration from "get-blob-duration";
import brandKitOptions from "../../Images/brandKitMoreOptions.svg"
import BrandKitKebab from "../brandKitKebab";
import Info from "../../Images/info.svg";
import BrandkitPreview from "../BrandKitPreview/brandkitPreview";
let toastId
const IntroKit = () => {
  const { brandkitState, brandkitDispatch, updateBrandKit, introFileRef, uploadInOutVideoBrandKit } = useBrandKit()
  const { brandKit, introOutroMenu } = brandkitState
  const [files, setFiles] = useState(null)
  const fileUuid = uuidv4();
  const presignedUrl = (val) => {
    let data = {
      fileName: val?.fileName,
      type: val?.type,
      contentType: val?.files?.type
    }
    const config = {
      url: "/api/capture/aws/getdocumentpresignedurl",
      method: "POST",
      data,
    };

    return captureClient(config);
  }
  const uploadVideoFileIntro = (e) => {
    if (
      e.target.files[0].type === "video/mp4" ||
      e.target.files[0].type === "video/webm"
    ) {
      if (e.target.files[0].size > 20971520) {
        toast.error("Maximum allowed file size is 20 MB")
      } else {
        setFiles(e.target.files[0]);
      }
    } else {
      toast.error("Only MP4 & WEBM files supported");
    }
  };
  useEffect(() => {
    if (files) {
      const fileName = `Flonnect_${new Date()
        .toJSON()
        .slice(0, 10)}_${fileUuid}`;
      const audio = new Audio();
      // const name = files?.name;
      audio.onloadedmetadata = async () => {
        let totalDuration = audio.duration * 1000;
        let d = Number(Math.floor(audio.duration));
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);
        var hour = h < 10 ? `0${h}` : h;
        var minute = m < 10 ? `0${m}` : m;
        var second = s < 10 ? `0${s}` : s;
        let durBlob = hour + ":" + minute + ":" + second;
        if (parseInt(totalDuration)) {
          toastId = toast.loading("Intro Video Adding....")
          createPresignedUrl({ fileName, files, type: "Intro" })


        } else {
          toastId = toast.loading("Intro Video Adding....")
          const reader = new FileReader();
          reader.onload = async function () {
            const blob = new Blob([reader.result]);
            const blobUrl = URL.createObjectURL(blob);
            await getBlobDuration(blobUrl).then(async function (duration) {
              totalDuration = duration * 1000;
              let d = Number(Math.floor(duration));
              var h = Math.floor(d / 3600);
              var m = Math.floor((d % 3600) / 60);
              var s = Math.floor((d % 3600) % 60);
              var hour = h < 10 ? `0${h}` : h;
              var minute = m < 10 ? `0${m}` : m;
              var second = s < 10 ? `0${s}` : s;
              durBlob = hour + ":" + minute + ":" + second;
              createPresignedUrl({ fileName, files, type: "Intro" })

            });
          };

          reader.readAsArrayBuffer(files);
        }
      };
      audio.src = URL.createObjectURL(files);
    }
  }, [files]);
  const { mutate: createPresignedUrl } = useMutation(presignedUrl, {
    onSuccess: async (res, val) => {
      if (res?.data?.signedUrl) {
        let result = await uploadInOutVideoBrandKit(
          val?.files,
          val?.fileName,
          val?.type,
          res?.data?.signedUrl
        );
        if (result) {
          let apiBody = {

            ...brandKit,
            introVideo: [
              {
                url: result,
                default: true,
              }
            ],

          };
          const apiObject = {
            method: "post",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(apiBody),
          };
          updateBrandKit(apiObject)
            .then((data) => {
              let userBrandKitDetails =
                data?.userBrandKitDetails;
              brandkitDispatch({
                type: "FETCH_BRAND_KIT",
                data: userBrandKitDetails,
              });
              toast?.success('Intro Video added...')
              toast.dismiss(toastId)
            })
            .catch((error) => {
              console.error("Error:", error);
              toast?.error('Something went wrong...')
            });
        } else {
          toast.error("Something went wrong")
          toast.dismiss(toastId)
        }
        setFiles(null)

      }
    },
    onError: (e) => {
      console.log(e);
      toast.error("Something went wrong")
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
  return (
    <ItemWrap mt={true}>
      <ItemRHS>
        <KitItemHead
          head={"Intro Video"}
          subHead={
            "Engage your audience instantly with a captivating intro video."
          }
        />
        <input
          type="file"
          ref={introFileRef}
          onChange={(e) => {
            uploadVideoFileIntro(e);
          }}
          accept="video/mp4,video/webm"
        />
        <VideoUploadWrap>
          <UploadVideoWrap onMouseLeave={(e) => {
            brandkitDispatch({
              type: "HANDLE_INTRO_OUTRO_MENU",
              data: {
                open: !introOutroMenu?.open,
                id: "",
              },
            });
          }}>

            {brandKit?.introVideo?.length > 0 ? (
              <>
                <UploadVideoSec style={{border:brandKit?.introVideo?.length > 0 && "1px solid #B293FB"}}>
                  <video src={brandKit?.introVideo[0]?.url} style={{ width: "100%", height: "100%" }}></video>

                  <IntroOutroKebab onClick={(e) => {
                    brandkitDispatch({
                      type: "HANDLE_INTRO_OUTRO_MENU",
                      data: {
                        open: !introOutroMenu?.open,
                        id: "intro",
                      },
                    });
                  }}>
                    <img src={brandKitOptions} draggable={false} />

                  </IntroOutroKebab>
                  {introOutroMenu?.open && introOutroMenu?.id === "intro" && (
                    <BrandKitKebab type="Intro" />
                  )}
                </UploadVideoSec>
              </>
            ) : (
              <>
                <UploadVideoSec onClick={() => {
                  if (!files) {
                    introFileRef?.current?.click()
                  }
                }}>

                  <img src={AddVideo} />
                  <SizeText>Add Intro video</SizeText>

                </UploadVideoSec>
                <SizeWrap>
                  <img src={Info} />
                  <SizeText>Max Size: 20MB</SizeText>
                </SizeWrap>
              </>
            )}

          </UploadVideoWrap>
        </VideoUploadWrap>
      </ItemRHS>
      <BrandkitPreview type="intro"/>
    </ItemWrap >
  );
};

export default IntroKit;

import React from "react";
import { BrandContWrapper, Indiv, Page } from "./brandkit-styled";
import LibraryHeadPart from "../LibraryPage/Components/Header/libraryHead";
import BrandHead from "./components/BrandHead/brandHead";
import BrandItems from "./components/BrandItems/brandItems";
import { captureClient } from "../../../utils/axios-utils";
import { useQuery } from "react-query";
import { useBrandKit } from "./context/brandContext";
const Brandkit = () => {
  const { brandkitState, brandkitDispatch } = useBrandKit();
  const getBrandKit = () => {
    const config = {
      url: "api/capture/brandkit/getbrandkit",
      method: "GET",
    };

    return captureClient(config);
  };
  const handleSuccess = (data) => {
    let userBrandKitDetails = data?.data?.userBrandKitDetails;
    brandkitDispatch({
      type: "FETCH_BRAND_KIT",
      data: {
        callout: userBrandKitDetails?.callout,
        font: userBrandKitDetails?.font,
        logo:userBrandKitDetails?.logo,
        backgroundTheme:userBrandKitDetails?.backgroundTheme,
        introVideo:userBrandKitDetails?.introVideo,
        outroVideo:userBrandKitDetails?.outroVideo,
      },
    });
  };
  const handleAcquireError = (err) => {};

  const { isLoading } = useQuery("brand-kit", getBrandKit, {
    retry: false,
    onSuccess: handleSuccess,
    onError: handleAcquireError,
  });
  return (
    <Indiv>
      <BrandContWrapper>
        <Page>
          <LibraryHeadPart text={"Brand Kit"} />
          <BrandHead />
          <BrandItems />
        </Page>
      </BrandContWrapper>
    </Indiv>
  );
};

export default Brandkit;

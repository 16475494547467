import React from "react";
import {
  FontStyleWrap,
  HeadingDescOpenedWrap,
  HeadingDescWrap,
  HeadingSaveWrap,
  ItemRHS,
  ItemWrap,
  SaveCancelWrap,
  TextWrap,
} from "../../brandkit-styled";
import KitItemHead from "../kitItemHead";
import EditPencil from "../../Images/editPencil.svg";
import { useBrandKit } from "../../context/brandContext";
import FontSec from "./componenets/fontSec";
import SaveTick from "../../Images/saveTick.svg";
import Close from "../../Images/close.svg";
import { toast } from "react-hot-toast";
import BrandkitPreview from "../BrandKitPreview/brandkitPreview";

const FontKit = () => {
  const { brandkitState, brandkitDispatch,updateBrandKit } = useBrandKit();
  const { fontMenu,brandKit } = brandkitState;
  return (
    <ItemWrap mt={true} font={true}>
      <ItemRHS>
        <KitItemHead
          head={"Font"}
          subHead={
            "Choose a font that enhances your brand's message and elevates your video content."
          }
        />
        <FontStyleWrap>
          {!fontMenu?.open ? (
            <HeadingDescWrap
              onClick={() => {
                brandkitDispatch({ type: "HANDLE_HEADING_FONT", data: true });
              }}
            >
              <TextWrap>{brandKit?.font?.fontFamily}</TextWrap>
              <img src={EditPencil} alt="no-icon" draggable={false} />
            </HeadingDescWrap>
          ) : (
            <HeadingDescOpenedWrap>
              <FontSec type={"head"} />
              <HeadingSaveWrap>
                <TextWrap>Font</TextWrap>
                <SaveCancelWrap>
                  <img
                    src={SaveTick}
                    alt="no-icon"
                    draggable={false}
                    onClick={() => {
                      brandkitDispatch({
                        type: "HANDLE_HEADING_FONT",
                        data: false,
                      });
                      let apiBody = {
                        ...brandKit
                      };
                      const apiObject = {
                        method: "post",
                        credentials: "include",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(apiBody),
                      };
                      updateBrandKit(apiObject)
                        .then((data) => {
                          let userBrandKitDetails =
                            data?.userBrandKitDetails;
                          brandkitDispatch({
                            type: "FETCH_BRAND_KIT",
                            data: userBrandKitDetails,
                          });
                          toast?.success('Heading updated...')
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                          toast?.success('Something went wrong...')
                        });
                    }}
                  />
                  <div />
                  <img
                    src={Close}
                    alt="no-icon"
                    draggable={false}
                    onClick={() => {
                      brandkitDispatch({
                        type: "HANDLE_HEADING_FONT",
                        data: false,
                      });
                    }}
                  />
                </SaveCancelWrap>
              </HeadingSaveWrap>
            </HeadingDescOpenedWrap>
          )}
        </FontStyleWrap>
      </ItemRHS>
      <BrandkitPreview type="font"/>
    </ItemWrap>
  );
};

export default FontKit;

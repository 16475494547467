import { createContext, useContext, useReducer } from "react";

const VideoContext = createContext(null);

const initialState = {
  videoData: {},
  isLoading: false,
  commentData: [],
  workspaceData: [],
  getVideo: null,
  userId: "",
  transcribeTextUrl: "",
  shareType: "",
  videoName: "",
  summerText: "",
  comments: "manage",
  moveTo: {
    // moveToId: "",
    open: false,
    workspaceId: "",
  },
  commentMenu: {
    commentId: "",
    open: false,
  },
  share: {
    open: false,
    sharedEmails: [],
    embed:false
  },
  embed: {
    open: false,
  },
  suggest: {
    open: false,
  },
  invite: {
    open: false,
  },
  videoNavMenu: {
    open: false,
  },
  deletePopup: {
    open: false,
  },
  commentPopup: {
    open: false,
  },
  edit: {
    open: false,
    commentId: "",
  },
  ownerChange: {
    open: false,
  },
};
const VideoReducer = (state, action) => {
  switch (action.type) {
    case "FETCHING_VIDEO_BY_ID":
      return {
        ...state,
        videoData: action.data?.vdata?.video,
        commentData: action.data?.vdata?.comments,
        userId: action.data?.vdata?.video?.userId,
        transcribeTextUrl: action.data?.vdata?.video?.subtitleUrl,
        shareType: action.data?.vdata?.video?.shareType,
        videoName: action.data?.vdata?.video?.videoName
          ? action.data?.vdata?.video?.videoName
          : action?.data?.vdata?.video?.id,
        summerText: action.data?.vdata?.video?.summary,
        getVideo: action?.data?.getVideo,
      };
    case "CHANGE_COMMENTS_TAB":
      return {
        ...state,
        comments: action.data,
      };
    case "OPEN_MOVETO":
      return {
        ...state,
        moveTo: {
          ...state.moveTo,
          open: true,
        },
        videoNavMenu: {
          ...state.videoNavMenu,
          open: false,
        },
      };
    case "CLOSE_MOVETO":
      return {
        ...state,
        moveTo: {
          ...state.moveTo,
          open: false,
          workspaceId: "",
        },
      };
    case "SELECT_WORKSPACE_TO_MOVE":
      return {
        ...state,
        moveTo: {
          ...state.moveTo,
          workspaceId: action.data,
        },
      };
    case "OPEN_COMMENT_MENU":
      return {
        ...state,
        commentMenu: {
          ...state.commentMenu,
          open: true,
          commentId: action?.data,
        },
      };

    case "CLOSE_COMMENT_MENU":
      return {
        ...state,
        commentMenu: {
          ...state.commentMenu,
          open: false,
          commentId: "",
        },
      };
    case "OPEN_SHARE":
      return {
        ...state,
        share: {
          ...state.share,
          open: true,
        },
      };
    case "CLOSE_SHARE":
      return {
        ...state,
        share: {
          ...state.share,
          open: false,
          sharedEmails: [],
          embed:false
        },
      };
    case "HANDLE_EMBED":
      return{
        ...state,
        share: {
          ...state.share,
          embed:action?.data
        },

      }
    case "FETCHING_SHARED_EMAILS":
      return {
        ...state,
        share: {
          ...state.share,
          sharedEmails: action?.data,
        },
      };
    case "OPEN_SUGGEST":
      return {
        ...state,
        suggest: {
          ...state.suggest,
          open: true,
        },
      };
    case "CLOSE_SUGGEST":
      return {
        ...state,
        suggest: {
          ...state.suggest,
          open: false,
        },
      };
    case "OPEN_INVITE":
      return {
        ...state,
        invite: {
          ...state.invite,
          open: true,
        },
      };
    case "CLOSE_INVITE":
      return {
        ...state,
        invite: {
          ...state.invite,
          open: false,
        },
      };

    case "OPEN_NAV_MENU":
      return {
        ...state,
        videoNavMenu: {
          open: true,
        },
      };

    case "CLOSE_NAV_MENU":
      return {
        ...state,
        videoNavMenu: {
          open: false,
        },
      };
    case "OPEN_DELETE_POPUP":
      return {
        ...state,
        deletePopup: {
          open: true,
        },
      };

    case "CLOSE_DELETE_POPUP":
      return {
        ...state,
        deletePopup: {
          open: false,
        },
      };
    case "FETCH_WORKSPACE_IN_VIDEOPAGE":
      return {
        ...state,
        workspaceData: action.data,
      };

    case "OPEN_COMMENT_POPUP":
      return {
        ...state,
        commentPopup: {
          open: true,
        },
      };

    case "CLOSE_COMMENT_POPUP":
      return {
        ...state,
        commentPopup: {
          open: false,
        },
        edit: {
          ...state.edit,
          open: false,
          commentId: "",
        },
      };

    case "EDIT_COMMENT":
      return {
        ...state,
        edit: {
          ...state.edit,
          open: true,
          commentId: action?.data,
        },
        commentPopup: {
          ...state.commentPopup,
          open: true,
        },
      };

    case "CHANGE_SHARE_TYPE":
      return {
        ...state,
        shareType: action?.data,
      };
    case "OPEN_OWNER_CHANGE":
      return {
        ...state,
        ownerChange: {
          open: true,
        },
      };

    case "CLOSE_OWNER_CHANGE":
      return {
        ...state,
        ownerChange: {
          open: false,
        },
      };
      case "OPEN_EMBED":
        return {
          ...state,
          embed: {
            ...state.embed,
            open: true,
          },
        }; case "CLOSE_EMBED":
        return {
          ...state,
          embed: {
            ...state.embed,
            open: false,
          },
        };
  }
};
const useVideoReducer = () => {
  return useReducer(VideoReducer, initialState);
};

export const VideoProvider = ({ children }) => {
  const [state, dispatch] = useVideoReducer();
  return (
    <VideoContext.Provider
      value={{
        videoState: state,
        videoDispatch: dispatch,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export const useVideo = () => {
  return useContext(VideoContext);
};

import axios from "axios";
import _static from "../config/static"
import { customToast } from "./toast-utils"
const { auth_Url,api_Url,capt_Url } =_static;

const errorHandler = (err) => {
    if(err?.config?.url?.includes("/auth/api/getcurrentuser") && err?.code === "ERR_NETWORK"){
        return Promise.reject(err)
    }

    if(err.code === 'ERR_NETWORK'){
        customToast({
            type: 'error',
            message: "Connection lost"
        })
        return Promise.reject(err)
    }

    if(err?.response?.status === 401) {
        return Promise.reject(err)  
    }

    let errors = err?.response?.data?.errors
    errors.forEach(errObj => {
        customToast({
            type: 'error',
            message: errObj?.message || "Something went wrong"
        })
    })

    return Promise.reject(err)
}
export const authClient = axios.create({ 
    baseURL: auth_Url,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true
})

export const dashClient = axios.create({ 
    baseURL: api_Url,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true
})
export const preSignedClient = axios.create({ 
    baseURL: api_Url,
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true
})
export const captureClient = axios.create({ 
    baseURL: capt_Url,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true
})

authClient.interceptors.response.use((response) => {
    return response.data
}, errorHandler)
dashClient.interceptors.response.use((response) => {
    return response.data
}, errorHandler)
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ContentDiv, InfoContainer, Removed, RemovedNav } from '../../Homepage/Homepage.styled';
import Grey from "../../Homepage/images/greyicon.svg";
import InviteIcon from "./inviteIcon.svg";
import { useNavigate } from "react-router-dom";
import { dashClient } from '../../../../utils/axios-utils';
import { useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../../../context/auth';
const WorkspaceInvite = () => {
  const navigate = useNavigate();
  const params = useParams();
  const auth = useAuth();
  const handleInviteSuccess = (data) => {
    toast.success("Invitation sent successfully...")
};
const handleInviteAcquireError = (err) => {
};
  const inviteWorkspace = () => {
    const apiData = { email: auth?.authState?.user?.email, workspaceId:params?.id }
    const config = {
      url: "flonnect/api/workspace/addmembervialink",
      method: "POST",
      data:apiData
    };

    return dashClient(config);
  };
  const { isLoading } = useQuery("workspace-invite", inviteWorkspace, {
    onSuccess: handleInviteSuccess,
    onError: handleInviteAcquireError,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(()=>{
    if(localStorage.getItem('inviteCode')){
      localStorage.removeItem('inviteCode')
    }
  },[])
    return (
        <Removed>
          <RemovedNav
            onClick={() => {
              navigate("/dashboard/library");
            }}
          >
            <img className="grey" src={Grey} alt="" draggable="false" />
          </RemovedNav>
          <ContentDiv>
            <InfoContainer>
              <img src={InviteIcon} alt="" draggable="false" height="40px" />
              <div className="info">Invitation sent to the workspace admin</div>
              <div className="description">
                <div className="requestInfo">
                  You can view workspace once the creator grant you to view their
                  workspace
                </div>
              </div>
            </InfoContainer>
          </ContentDiv>
        </Removed>
      );
};

export default WorkspaceInvite;
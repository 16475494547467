import React from 'react'
import { KebabDiv, KebabEditImg, KebabTrashImg, StepKebabSectionWrap } from '../../CaptureDemoEdit/captureDemoEdit-styled'
import { useBrandKit } from '../context/brandContext'
import toast from 'react-hot-toast'

const BrandKitKebab = (props) => {
    const { type } = props
    const { brandkitState, brandkitDispatch, updateBrandKit, introFileRef, uploadInOutVideoBrandKit, outroFileRef } = useBrandKit()
    const { brandKit, introOutroMenu } = brandkitState
    return (
        <StepKebabSectionWrap style={{ padding: "10px 0px" }}>
            {type !== "logo" && (
                <KebabDiv
                    onClick={() => {
                        if (type === "Intro") {
                            introFileRef?.current?.click()
                        } else {
                            outroFileRef?.current?.click()
                        }
                        brandkitDispatch({
                            type: "HANDLE_INTRO_OUTRO_MENU",
                            data: {
                                open: !introOutroMenu?.open,
                                id: "",
                            },
                        });
                    }}
                >
                    <KebabEditImg />
                    <p>Edit</p>
                </KebabDiv>
            )}

            <KebabDiv
                style={{ borderRadius: "10px" }}
                onClick={(e) => {
                    let apiBody = {
                        ...brandKit,
                        introVideo: type === "Intro" ? null : brandKit?.introVideo,
                        outroVideo: type === "Outro" ? null : brandKit?.outroVideo,
                        logo: type === "logo" ? [{
                            url: brandKit?.logo[0]?.url,
                            default: true
                        }] : brandKit?.logo
                    };
                    const apiObject = {
                        method: "post",
                        credentials: "include",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(apiBody),
                    };
                    updateBrandKit(apiObject)
                        .then((data) => {
                            let userBrandKitDetails =
                                data?.userBrandKitDetails;
                            brandkitDispatch({
                                type: "FETCH_BRAND_KIT",
                                data: userBrandKitDetails,
                            });
                            toast.success(`${type} Video Removed...`)
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                            toast?.error('Something went wrong...')
                        });
                }}
            // disable={documentData?.length === 1}
            >
                <KebabTrashImg />
                <p>Delete</p>
            </KebabDiv>
        </StepKebabSectionWrap>
    )
}

export default BrandKitKebab

const theme = {
  colors: {
    main: "#6C5DD3",
    hover: "#6A36F0",
    title1: "#585763",
    title2: "#3C453C",
    menuHover: "#F0EEFB",
    menuBackground: "#1E1E1E",
    menuColor: "#EDEDED",
    placeHolder: "#667085",
    lightText1: "#404B59",
    signInText: "#344054",
    button: "#6528F7",
    lightPurple: "#B293FB",
    background: "#f5f5f5",
    hoverBorder: "#9870FA",
    rightBorder: "#EDEFF2",
    lightGrey: "#F4F6F8",
    semiBlack: "#1E2126",
    blackShades: "#66686f",
    timecolor: "#242730ad",
    defaultThumbColor: "#282F3D",
    hoverButton: "#4908E2",
    deleteBtn: "#FF3B30",
    createBtn :"#7F4CF8",
    divider: "#DCE1E9",
    sidebar:"#ffffff",
   
  },
  breakpoints: {
    desktop: `@media only screen and (max-width: 940px)`,
    tab: `@media only screen and (max-width: 770px)`,
    tabview:`@media only screen and (min-width: 770px)`,
    mediumView:`@media only screen and (max-width: 650px)`,
    smallView:`@media only screen and (max-width: 536px)`,
    largeLapView:`@media only screen and (max-width: 1640px)`,
    lapView:`@media only screen and (max-width: 1440px)`,
    tabViewCapt:`@media only screen and (max-width: 1120px)`,
    smallTabView : `@media only screen and (max-width: 980px)`,
    tabHeight:`@media only screen and (max-height: 800px)`,
    tabSmallHeight:`@media only screen and (max-height: 650px)`

  },
};
export default theme;
